/*@import '@material-design-icons/font';*/
/* login */
@import url('login/login.scss');
/* css */
@import url('layout.scss');
@import url('forms.scss');
@import url('commons.scss');
@import url('icons.scss');
@import url('tools.scss');
/* component */
@import url('filter/filter.scss');
/* widgets */
@import url('widgets/base.scss');
/* theme */
@import url('theme/navy/navy.scss');
@import url('theme/dark/dark.scss');
/* font */
@import url('pretendard.css');
/* imgs */
@import url('sensorImg.scss');

/* map */
@import url('temporary.scss');

@import url('custom.scss');

/* Reset */
*{-webkit-text-size-adjust:none; box-sizing: border-box;}
body,p,h1,h2,h3,h4,h5,h6,ul,ol,li,dl,dt,dd,table,th,td,form,fieldset,legend,input,textarea,button,select{margin:0;padding:0}
body,input,textarea,select,button,table,h1,h2,h3,h4,h5{font-size:.8rem;color:black;letter-spacing: -0.5px;}
body{*word-break:break-word;-ms-word-break:break-word;overflow-y:scroll}
img,fieldset,iframe{border:0 none}
ul,ol,li{list-style:none; margin: 0; padding: 0;}
input,select,button{vertical-align:middle}
input[type='radio'],input[type='checkbox']{margin-right:10px; border:none;}
img{vertical-align:middle}
i,em,address{font-style:normal}
label,button{cursor:pointer}
button{margin:0;padding:0}
a{text-decoration:none !important; color:#4b4b4b}
a:hover{text-decoration:none !important}
button *{position:relative}
button img{left:-3px;*left:auto}
button, button:focus, button:active{
  border:none;
  outline:none}
/* input, input:focus, input:active {
    border:none;
    outline:none} */
blockquote:before, blockquote:after,
q:before, q:after{content: ''; content: none;}
table{border-collapse: collapse; border-spacing: 0;}

.blind{display: inline-block; text-align: left; text-indent: -99999999px; font-size: 0;}