$breakpoint-mobile: 570px;
$breakpoint-tablet: 758px;
$breakpoint-labtop: 1024px;
$breakpoint-desktop: 1200px;

.leaflet-container {
    background-color: transparent;
    width: 100%;
    height: 100%;
}

.leaflet-top.leaflet-left {
    display: flex;
    flex-wrap: wrap;


    .custom-leaflet-control {
        //width: 100%;
        height: 100%;

        a {
            width: 30px;
            height: 30px;
        }
    }

    .control-container {
        >*{
            margin-right: .4rem;
        }
    }

    @media(max-width: $breakpoint-tablet){
        .control-container{
            display:flex;
            align-items: flex-start;
            flex-direction: column;
            flex-basis: 100%;
            width: min-content;
            max-width: max-content;

            .item-container{
                margin-left: 0;
            }

            >*{
                margin-bottom: .4rem;
            }
        }

    }
}

.geofence-name-label {
    font-size: 0.85rem;
    font-weight: 500;
    //color: #eee;
    text-shadow: -1px 0 #f2f1f6, 0 1px #f2f1f6, 1px 0 #f2f1f6, 0 -1px #f2f1f6;
    word-break: keep-all;
    width: initial !important;
    height: initial !important;

    background-color: initial;
    border: initial;
    box-shadow: initial;
    padding: unset;
    &:before {
        content: none;
    }
}

.category-marker {
    .marker-img {
        width: 100%;
        height: 100%;
    }
    .marker-img.replace-img {
        display: flex;
        justify-content: center;
        align-items: center;
        &>div {
            width: 100%;
            height: 100%;
            background-color: #3076c1;
            border-radius: 50%;
        }
    }
    .marker-label {
        background-color: rgba(255,255,255, .8);
        border-radius: 3px;
        position: absolute;
        white-space: nowrap;
        left: 50%;
        transform: translateX(-50%);
    }

    // Ani marker
    .green {
        color: #009313;
    }

    .orange {
        color: #f17c57fa;
    }

    .blue {
        color: #3652D9;
    }

    .rose {
        color:#FF66CC;
    }

    .circle {
        border-radius: 50%;
        box-shadow: 0 0 1px 1px #0000001a;
    }

    .square {
        border-radius: 10%;
        box-shadow: 0 0 1px 1px #0000001a;
    }

    .pulse-S {
        animation: pulse-animation-S 3s infinite;
    }

    .pulse-U {
        animation: pulse-animation-U 3s infinite;
    }

    .pulse-F {
        animation: pulse-animation-F 3s infinite;
    }

    .pulse-S.rssi {
        animation: pulse-animation-S-rssi 3s infinite;
    }

    .pulse-U.rssi {
        animation: pulse-animation-U-rssi 3s infinite;
    }

    .pulse-F.rssi {
        animation: pulse-animation-F-rssi 3s infinite;
    }
}


@keyframes pulse-animation-S {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes pulse-animation-U {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    }
    70% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes pulse-animation-F {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    }
    30% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
    60% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes pulse-animation-S-rssi {
    0% {
        box-shadow: 0 0 0 0;
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes pulse-animation-U-rssi {
    0% {
        box-shadow: 0 0 0 0;
    }
    70% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes pulse-animation-F-rssi {
    0% {
        box-shadow: 0 0 0 0;
    }
    30% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
    60% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

.leaflet-control-layers.leaflet-control-layers-expanded {
    padding: 6px;
    & .leaflet-control-layers-overlays{
        & label {
            &:last-child {
                margin-bottom: 0;
            }
            &>div {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                &>.leaflet-control-layers-selector {
                    top: 0;
                    margin: 0 5px 0 0;
                }

                &>span {
                    vertical-align: middle;
                }
            }
        }
    }
}

.leaflet-control-zoom {
    & .leaflet-control-zoom-in, & .leaflet-control-zoom-out,
    & .leaflet-control-zoom-in:hover, & .leaflet-control-zoom-out:hover {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.leaflet-control {
    .leaflet-map-nameplate  {
        height: 28px;
        display: flex;
        align-items: center;
        grid-gap: 6px;
        gap: 6px;
        padding: 0 0.5rem;
        border-radius: 2px;
        background-color: #000000;
        color: #FFFFFF !important;
        max-width: 8rem;
        position: relative;

        div {
            color: #FFFFFF !important;
        }

        span[class*="icon-"]{
            -webkit-filter: invert(1);
        }
    }

}



// create by jinbeom-jung
#root .app-container {

    //map use checkbox color
    .checkbox-container.leaflet-map-checkbox {
        padding: 5px 3px 1px 5px;
        background-color :#453e6f ;
        .pnt-checkbox .checkbox-effect {
            border: 1px solid #d0cfd8;
            background-color: #f0eff5;
        }
        .pnt-checkbox p {
            color: #ffffff;
        }
        .pnt-checkbox:has(input:checked) p {
            color: #ffffff;
        }
    }

    // map nameplate color
    .pnt-nameplate.leaflet-map-nameplate {
        background-color :#000000 ;
        .material-icons-round{
            color: #ffffff;
        }
    }

    // map button color
    .pnt-btn.leaflet-map-btn {
        color: #ffffff;
        background-color :#453e6f;
        .material-icons-round{
            color: #ffffff;
        }
    }

    // map group-select color
    .leaflet-map-select.react-select .react-select__control .select__btn{
        border : none;
        background-color: #ffffff ;
        div{
            color : #000000 ;
        }
    }

    // map group-select-option color
    #select-container .react-select__menu .react-select__menu-list .react-select__option{
        color : #000000;
        background-color : #ffffff;
    }
}
