@import '../pnt/theme/default/_default';
@import '../pnt/_font';
@import '../pnt/tools';

$breakpoint-laptop: 1024px;
$widget-header-height: 40px;

.widget-card {
    box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03), 0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
        0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05), 0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(32, 39, 140, 0.125);
    border-radius: 0.25rem;
}

@media (max-width: 570px) {
    .card-header.widget-header.hidden-header.action-header:hover {
        min-height: 7rem;
        border-bottom: 1px solid rgba(32, 39, 140, 0.125);
    }
}
.widget-header {
    display: flex;
    align-items: center;
    height: $widget-header-height;
    padding: 0 0.625rem 0 0.75rem;
    border-bottom: 1px solid rgba(32, 39, 140, 0.125);
    transition: height 500ms ease-in-out 100ms, min-height 500ms ease-in-out 100ms, border-bottom 500ms ease-in-out 100ms;

    &.hidden-header {
        min-height: 0;
        height: 0;
        overflow: hidden;
        border-bottom: none;
    }
    &.hidden-header:after {
        content: '';
        height: 13px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    &.hidden-header:hover {
        height: $widget-header-height;
        min-height: $widget-header-height;
        border-bottom: 1px solid rgba(32, 39, 140, 0.125);
    }
}

.widget-header-left,
.widget-header-right {
    display: flex;
    align-items: center;
}
.widget-header-right {
    margin-left: auto;
}

.widget-body {
    flex: 1 1 auto;
    padding: 0.75rem;
    height: calc(100% - #{$widget-header-height});
}

.grid-sub-menu {
    &.dropdown-menu {
        min-width: 0;
        padding: 0.25rem 0;
    }
    &.dropdown-menu.show::before,
    &.dropdown-menu.show::after {
        content: none;
    }
    &.dropdown-menu a:hover {
        text-decoration: none;
    }
    .dropdown-item {
        padding: 0.25rem 0.75rem;
    }
}
//.react-contextmenu.react-contextmenu--visible {
//    background-color: #fff;
//    background-clip: padding-box;
//    border: 1px solid rgba(32, 39, 140, 0.125);
//    border-radius: 0.3rem;
//    padding: 0.25rem 0.75rem;
//}
//
//.react-contextmenu a,
//.react-contextmenu a:hover {
//    text-decoration: none;
//    color: #495057;
//}
//
//.react-contextmenu .react-contextmenu-item {
//    cursor: pointer;
//}

//.list-group a:hover {
//    text-decoration: none;
//}
//
//.list-group-item {
//    border: none;
//    padding: 0.25rem;
//}

//Dashboard Edit을 사용하는 경우
.temp-widget {
    border: 2px solid #fd9f33 !important;
}

.app-container{
    & .app-main > .app-main__outer > .app-main__inner{
        & .material-icons-round {
            #root &.app-page-title {
                background-color: #28348a;
                position: sticky;
                top: 0;
                z-index: 100;
                padding: 15px 20px;
                margin: -30px -30px 30px;
                height: max-content;
                @media(max-width: $breakpoint-laptop) {
                    text-align: center;
                }

                .page-title-heading {
                    color: #bcc0bd;

                    // &:before {
                    //     content: "";
                    //     display: inline-block;
                    //     width: 2.7rem;
                    //     height: 2.7rem;
                    //     background-color: #FF7800FF;
                    //     margin-right: 1rem;
                    //     border-radius: 10%;

                    //     @media(max-width: $breakpoint-laptop) {
                    //         margin: 0;
                    //     }
                    // }
                }

                .page-title-actions {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}

////위젯 생성시 나오는 리스트 아이템 목록 css
//.list-group-item {
//    &.layout {
//        width: 100%;
//        display: flex;
//        justify-content: space-between;
//        align-items: center;
//        cursor: pointer;
//
//        & .badge {
//            font-size: 80%;
//        }
//        &:hover {
//            background-color: $gray-100;
//            font-weight: 700;
//        }
//
//        &.active {
//            font-weight: 700;
//            margin-top: 0;
//            border: 0;
//            background-color: $gray-200;
//
//            &:hover {
//                background-color: $gray-200;
//            }
//        }
//    }
//    &:hover {
//        background-color: $gray-100;
//    }
//}

// SH v3 app content 여백
.app-container .app-main .app-main__outer .app-main__inner .app-page-content {
    padding: 1rem 1rem 2.5rem 1rem;

    // 대시보드 전체화면
    &.dashboard-fullscreen {
        padding-top: calc(1rem + 70px);
    }
}

//전체화면 모드의 헤더
.dashboard-title-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color:white;
    box-shadow: $card-shadow;
    height:70px;
    padding:10px 20px;
    display:flex;
    align-items: center;
    justify-content: space-between;

    & .dashboard-logo {
        background-image: none;
        white-space: nowrap;
        line-height: auto;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: -1px;
        color: $default-brand;
    }
    & .dashboard-title{
        padding: .2rem 1rem;
        background-color: rgba(255,255,255, 0.2);
        border-radius: 100rem;
        & .dashboard-sub{
            @include p3-n();
            opacity: .6;
        }
        & .icon-next{
            opacity: .6;
            margin-top: -3px;
        }
        & .dashboard-main{
            @include h5-b();
            margin: 0 .5rem;
        }
    }
    & .dashboard-title-time{
        margin-left: .5rem;
        @include p1-b();
    }
}