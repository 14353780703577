////////////////////////////////////////
//아이콘 세팅

@mixin icons {
  background-repeat: no-repeat;
  border: none;
  font-size: 0;
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
}
span[class*="icon-"] {
  @include icons();
}

$icon: 20px; //실제작업은 40px인데 왜 절반으로 해야하는지는 연구 필요
$icon-gap: 10px;

$p1: calc((#{$icon} * -0) - (#{$icon-gap} * 0));
$p2: calc((#{$icon} * -1) - (#{$icon-gap} * 1));
$p3: calc((#{$icon} * -2) - (#{$icon-gap} * 2));
$p4: calc((#{$icon} * -3) - (#{$icon-gap} * 3));
$p5: calc((#{$icon} * -4) - (#{$icon-gap} * 4));
$p6: calc((#{$icon} * -5) - (#{$icon-gap} * 5));
$p7: calc((#{$icon} * -6) - (#{$icon-gap} * 6));
$p8: calc((#{$icon} * -7) - (#{$icon-gap} * 7));
$p9: calc((#{$icon} * -8) - (#{$icon-gap} * 8));
$p10: calc((#{$icon} * -9) - (#{$icon-gap} * 9));

@mixin bgp($x, $y) {
  background-position: $x $y;
}

@mixin icon-util {
  @include icons();
  background-size: calc((#{$icon} * 9) + (#{$icon-gap} * 8)); //가로 9줄
  background-image: url("../images/util.svg");
}
@mixin icon-direction {
  @include icons();
  background-size: calc((#{$icon} * 4) + (#{$icon-gap} * 3)); //가로 4줄
  background-image: url("../images/direction.svg");
}
@mixin icon-flag {
  @include icons();
  width: 20px !important;
  height: 16px !important;
  flex: unset !important;
  border-radius: 3px !important;
  background-size: 360px;
  background-image: url("../images/flag.JPG");
  box-shadow: 0 0 0 1px rgb(222, 222, 222) inset;
  filter: none !important;
}

////////////////////////////////////////
//아이콘 기능

//아이콘 사이즈
.icon-tiny {
  transform: scale(0.5) !important;
}
.icon-small {
  transform: scale(0.7) !important;
}
.icon-big {
  transform: scale(1.3) !important;
}
.icon-large {
  transform: scale(2) !important;
}

//아이콘용 보더
.icon-border {
  position: relative;
  &::after {
    content: "";
    width: 200%;
    height: 200%;
    border: 1px solid lightgray !important;
    border-radius: 0.25rem;
    display: block;
    position: absolute;
    top: -50%;
    left: -50%;
  }
}

//아이콘 오프
span[class*="icon-off"] {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 40%;
    width: 1.5px;
    height: 20px;
    background-color: black;
    box-shadow: 0px 0px 5px white;
    transform: rotate(45deg);
  }
}

//아이콘 필터
.icon-filter-red {
  filter: invert(20%) sepia(100%) saturate(3000%) hue-rotate(359deg)
  brightness(94%) contrast(100%) !important;
}
.icon-filter-blue {
  filter: invert(20%) sepia(100%) saturate(3000%) hue-rotate(230deg)
  brightness(94%) contrast(100%) !important;
}
.icon-filter-gray {
  filter: none !important;
  opacity: 0.3 !important;
}
.icon-filter-white {
  filter: invert(1) !important;
}
.icon-filter-brand {
  filter: invert(49%) sepia(82%) saturate(2656%) hue-rotate(1deg)
  brightness(105%) contrast(103%) !important;
}
.icon-filter-black {
  filter: none !important;
}

//아이콘 애니메이션
.btn-animation-move {
  padding-right: 2.5rem !important;
  position: relative;
  & span {
    animation-name: moving;
    animation-iteration-count: infinite;
    animation-duration: 0.7s;
    -webkit-animation-name: moving;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 0.7s;
    -moz-animation-name: moving;
    -moz-animation-iteration-count: infinite;
    -moz-animation-duration: 0.7s;

    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
}
@keyframes moving {
  from {
    right: 5px;
  }
  to {
    right: 10px;
  }
}
@-webkit-keyframes moving {
  from {
    right: 5px;
  }
  to {
    right: 10px;
  }
}
@-moz-keyframes moving {
  from {
    right: 5px;
  }
  to {
    right: 10px;
  }
}

////////////////////////////////////////
//icon-direction
.icon-back {
  @include bgp($p1, $p1);
  @include icon-direction();
}
.icon-next {
  @include bgp($p1, $p2);
  @include icon-direction();
}
.icon-up {
  @include bgp($p1, $p3);
  @include icon-direction();
}
.icon-down {
  @include bgp($p1, $p4);
  @include icon-direction();
}
.icon-back-arrow {
  @include bgp($p1, $p5);
  @include icon-direction();
}
.icon-next-arrow {
  @include bgp($p1, $p6);
  @include icon-direction();
}
.icon-up-arrow {
  @include bgp($p1, $p7);
  @include icon-direction();
}
.icon-back-arrowCircle {
  @include bgp($p1, $p8);
  @include icon-direction();
}
.icon-next-arrowCircle {
  @include bgp($p1, $p9);
  @include icon-direction();
}
.icon-up-arrowCircle {
  @include bgp($p1, $p10);
  @include icon-direction();
}

.icon-back-double {
  @include bgp($p2, $p1);
  @include icon-direction();
}
.icon-next-double {
  @include bgp($p2, $p2);
  @include icon-direction();
}
.icon-up-double {
  @include bgp($p2, $p3);
  @include icon-direction();
}
.icon-down-double {
  @include bgp($p2, $p4);
  @include icon-direction();
}
.icon-down-arrow {
  @include bgp($p2, $p5);
  @include icon-direction();
}
.icon-side-arrow {
  @include bgp($p2, $p6);
  @include icon-direction();
}
.icon-updown-arrow {
  @include bgp($p2, $p7);
  @include icon-direction();
}
.icon-down-arrowCircle {
  @include bgp($p2, $p8);
  @include icon-direction();
}
.icon-side-arrowCircle {
  @include bgp($p2, $p9);
  @include icon-direction();
}
.icon-updown-arrowCircle {
  @include bgp($p2, $p10);
  @include icon-direction();
}

.icon-back-light {
  @include bgp($p3, $p1);
  @include icon-direction();
}
.icon-next-light {
  @include bgp($p3, $p2);
  @include icon-direction();
}
.icon-up-light {
  @include bgp($p3, $p3);
  @include icon-direction();
}
.icon-down-light {
  @include bgp($p3, $p4);
  @include icon-direction();
}
.icon-back-arrow-light {
  @include bgp($p3, $p5);
  @include icon-direction();
}
.icon-next-arrow-light {
  @include bgp($p3, $p6);
  @include icon-direction();
}
.icon-up-arrow-light {
  @include bgp($p3, $p7);
  @include icon-direction();
}
.icon-back-arrowCircle-light {
  @include bgp($p3, $p8);
  @include icon-direction();
}
.icon-next-arrowCircle-light {
  @include bgp($p3, $p9);
  @include icon-direction();
}
.icon-up-arrowCircle-light {
  @include bgp($p3, $p10);
  @include icon-direction();
}

.icon-back-double-light {
  @include bgp($p4, $p1);
  @include icon-direction();
}
.icon-next-double-light {
  @include bgp($p4, $p2);
  @include icon-direction();
}
.icon-up-double-light {
  @include bgp($p4, $p3);
  @include icon-direction();
}
.icon-down-double-light {
  @include bgp($p4, $p4);
  @include icon-direction();
}
.icon-down-arrow-light {
  @include bgp($p4, $p5);
  @include icon-direction();
}
.icon-side-arrow-light {
  @include bgp($p4, $p6);
  @include icon-direction();
}
.icon-updown-arrow-light {
  @include bgp($p4, $p7);
  @include icon-direction();
}
.icon-down-arrowCircle-light {
  @include bgp($p3, $p8);
  @include icon-direction();
}
.icon-side-arrowCircle-light {
  @include bgp($p3, $p9);
  @include icon-direction();
}
.icon-updown-arrowCircle-light {
  @include bgp($p3, $p10);
  @include icon-direction();
}

////////////////////////////////////////
//icon-util
.icon-search {
  @include bgp($p1, $p1);
  @include icon-util();
}
.icon-info {
  @include bgp($p1, $p2);
  @include icon-util();
}
.icon-info-fill {
  @include bgp($p1, $p3);
  @include icon-util();
}
.icon-info-itallic {
  @include bgp($p1, $p4);
  @include icon-util();
}
.icon-zoomin {
  @include bgp($p1, $p5);
  @include icon-util();
}
.icon-zoomout {
  @include bgp($p1, $p6);
  @include icon-util();
}
.icon-plusCircle {
  @include bgp($p1, $p7);
  @include icon-util();
}
.icon-minusCircle {
  @include bgp($p1, $p8);
  @include icon-util();
}
.icon-hamburger {
  @include bgp($p1, $p9);
  @include icon-util();
}
.icon-more {
  @include bgp($p1, $p10);
  @include icon-util();
}

.icon-person {
  @include bgp($p2, $p1);
  @include icon-util();
}
.icon-people {
  @include bgp($p2, $p2);
  @include icon-util();
}
.icon-profile-fill {
  @include bgp($p2, $p3);
  @include icon-util();
}
.icon-profile {
  @include bgp($p2, $p4);
  @include icon-util();
}
.icon-board {
  @include bgp($p2, $p5);
  @include icon-util();
}
.icon-setting {
  @include bgp($p2, $p6);
  @include icon-util();
}
.icon-alert {
  @include bgp($p2, $p7);
  @include icon-util();
}
.icon-warningBalloon {
  @include bgp($p2, $p8);
  @include icon-util();
}
.icon-warning {
  @include bgp($p2, $p9);
  @include icon-util();
}
.icon-copyright {
  @include bgp($p2, $p10);
  @include icon-util();
}

.icon-home {
  @include bgp($p3, $p1);
  @include icon-util();
}
.icon-building {
  @include bgp($p3, $p2);
  @include icon-util();
}
.icon-camera {
  @include bgp($p3, $p3);
  @include icon-util();
}
.icon-phone {
  @include bgp($p3, $p4);
  @include icon-util();
}
.icon-mail {
  @include bgp($p3, $p5);
  @include icon-util();
}
.icon-email {
  @include bgp($p3, $p6);
  @include icon-util();
}
.icon-picture {
  @include bgp($p3, $p7);
  @include icon-util();
}
.icon-calendar {
  @include bgp($p3, $p8);
  @include icon-util();
}
.icon-clock {
  @include bgp($p3, $p9);
  @include icon-util();
}
.icon-globe {
  @include bgp($p3, $p10);
  @include icon-util();
}

.icon-fullscreen {
  @include bgp($p4, $p1);
  @include icon-util();
}
.icon-refresh {
  @include bgp($p4, $p2);
  @include icon-util();
}
.icon-reback {
  @include bgp($p4, $p3);
  @include icon-util();
}
//v.3 에선 icon-replay -> icon-reset 로 명칭변경 해야함
.icon-replay {
  @include bgp($p4, $p4);
  @include icon-util();
}
.icon-resize {
  @include bgp($p4, $p5);
  @include icon-util();
}
.icon-move {
  @include bgp($p4, $p6);
  @include icon-util();
}
.icon-close {
  @include bgp($p4, $p7);
  @include icon-util();
}
.icon-check {
  @include bgp($p4, $p8);
  @include icon-util();
}
.icon-plus {
  @include bgp($p4, $p9);
  @include icon-util();
}
.icon-minus {
  @include bgp($p4, $p10);
  @include icon-util();
}

.icon-edit {
  @include bgp($p5, $p1);
  @include icon-util();
}
.icon-delete {
  @include bgp($p5, $p2);
  @include icon-util();
}
.icon-clipboard {
  @include bgp($p5, $p3);
  @include icon-util();
}
.icon-clip {
  @include bgp($p5, $p4);
  @include icon-util();
}
.icon-history {
  @include bgp($p5, $p5);
  @include icon-util();
}
.icon-favorite {
  @include bgp($p5, $p6);
  @include icon-util();
}
.icon-file {
  @include bgp($p5, $p7);
  @include icon-util();
}
.icon-copy {
  @include bgp($p5, $p8);
  @include icon-util();
}
.icon-addBoard {
  @include bgp($p5, $p9);
  @include icon-util();
}
.icon-download {
  @include bgp($p5, $p10);
  @include icon-util();
}

.icon-wifi {
  @include bgp($p6, $p1);
  @include icon-util();
}
.icon-eye {
  @include bgp($p6, $p2);
  @include icon-util();
}
.icon-lock {
  @include bgp($p6, $p3);
  @include icon-util();
}
.icon-alarm {
  @include bgp($p6, $p4);
  @include icon-util();
}
.icon-sound {
  @include bgp($p6, $p5);
  @include icon-util();
}
.icon-cctv {
  @include bgp($p6, $p6);
  @include icon-util();
}
.icon-movieList {
  @include bgp($p6, $p7);
  @include icon-util();
}
.icon-target {
  @include bgp($p6, $p8);
  @include icon-util();
}
.icon-download-line {
  @include bgp($p6, $p9);
  @include icon-util();
}
.icon-upload-line {
  @include bgp($p6, $p10);
  @include icon-util();
}

.icon-heart {
  @include bgp($p7, $p1);
  @include icon-util();
}
.icon-star {
  @include bgp($p7, $p2);
  @include icon-util();
}
.icon-graph {
  @include bgp($p7, $p3);
  @include icon-util();
}
.icon-filter {
  @include bgp($p7, $p4);
  @include icon-util();
}
.icon-tool {
  @include bgp($p7, $p5);
  @include icon-util();
}
.icon-tools {
  @include bgp($p7, $p6);
  @include icon-util();
}
.icon-capture {
  @include bgp($p7, $p7);
  @include icon-util();
}
.icon-bluetooth {
  @include bgp($p7, $p8);
  @include icon-util();
}
.icon-rain {
  @include bgp($p7, $p9);
  @include icon-util();
}
.icon-sun {
  @include bgp($p7, $p10);
  @include icon-util();
}

.icon-createtab {
  @include bgp($p8, $p1);
  @include icon-util();
}
.icon-newtab {
  @include bgp($p8, $p2);
  @include icon-util();
}
.icon-forder {
  @include bgp($p8, $p3);
  @include icon-util();
}
.icon-findFile {
  @include bgp($p8, $p4);
  @include icon-util();
}
.icon-findFile-fill {
  @include bgp($p8, $p5);
  @include icon-util();
}
.icon-customer {
  @include bgp($p8, $p6);
  @include icon-util();
}
.icon-disconnect {
  @include bgp($p8, $p7);
  @include icon-util();
}
.icon-star-line {
  @include bgp($p8, $p8);
  @include icon-util();
}
.icon-heart-line {
  @include bgp($p8, $p9);
  @include icon-util();
}
.icon-alarm-line {
  @include bgp($p8, $p10);
  @include icon-util();
}
.icon-monitor {
  @include bgp($p9, $p1);
  @include icon-util();
}
.icon-sensor {
  @include bgp($p9, $p2);
  @include icon-util();
}
.icon-refrigerator {
  @include bgp($p9, $p3);
  @include icon-util();
}
/////////////////////////////////////////
// 기본도형
.icon-box {
  background-color: black;
}
.icon-circle {
  background-color: black;
  border-radius: 100px;
}

///////////////////////////////////////
//로고
@mixin icon-logo {
  background-image: url("../images/logo_indoorplus.svg");
  width: 100px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 100px;
  display: block;
}
.pnt-logo {
  @include icon-logo();
  background-position: 0px 0px;
  &.logo-o-o {
    background-position: 0px 0px;
  }
  &.logo-o-w {
    background-position: 0px -25px;
  }
  &.logo-w-o {
    background-position: 0px -50px;
  }
  &.logo-b-o {
    background-position: 0px -75px;
  }
  &.logo-b-w {
    background-position: 0px -100px;
  }
  &.logo-w-b {
    background-position: 0px -125px;
  }
  &.logo-w-w {
    background-position: 0px -150px;
  }
  &.logo-b-b {
    background-position: 0px -175px;
  }
  &.logo-o-b {
    background-position: 0px -200px;
  }
}

///////////////////////////////////////
//국기 아이콘
.icon-kr {
  @include icon-flag();
  background-position: calc((22.5px * -6) - 2px) calc((22.5px * -7) - 4px);
}
.icon-eng {
  @include icon-flag();
  background-position: calc((22.5px * -6) - 2px) calc((22.5px * -14) - 4px);
}
.icon-jp {
  @include icon-flag();
  background-position: calc((22.5px * -14) - 2px) calc((22.5px * -6) - 4px);
}
.icon-cn {
  @include icon-flag();
  background-position: calc((22.5px * -9) - 2px) calc((22.5px * -2) - 4px);
}
