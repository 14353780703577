@import "../tools";
@import "../variables";

.IV-injection-table {

  & .flx-row {
    align-items: stretch;
    & div {
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      //height: $form-height;
      border-left: 1px solid;

    }
    & > div:first-child {
      border-left: none;
    }
    // button 및 icon 영역
    & > div:last-child {
      width: 28px;
    }
  }
  & div {
    color: $form-txt;
    font-size: 0.8rem;
  }

  & .IV-info {
    &:hover {
      cursor: pointer;
    }
  }

}