//form의 기본 셋팅을 위한 컬러
$default-brand: #f37321;
$default-secondary: #3e66fb;
$default-success: #3ac062;
$default-warning: #ffc107;
$default-danger: #ff3333;
$default-info: #3bc1ff;
$default-brand-l: mix($default-brand, white, 20%);
$default-brand-d: mix($default-brand, black, 80%);
$default-secondary-l: mix($default-secondary, white, 20%);
$default-secondary-d: mix($default-secondary, black, 80%);
$default-success-l: mix($default-success, white, 20%);
$default-success-d: mix($default-success, black, 80%);
$default-warning-l: mix($default-warning, white, 20%);
$default-warning-d: mix($default-warning, black, 80%);
$default-danger-l: mix($default-danger, white, 20%);
$default-danger-d: mix($default-danger, black, 80%);
$default-info-l: mix($default-info, white, 20%);
$default-info-d: mix($default-info, black, 80%);

//////////////////////////////////////////////////////
/* 1. 기본 컬러 세팅 - form, layout, commons에 사용 */

//---violet gray----
// $white: #fff;
// $gray-100: #f6f6f9;
// $gray-200: #eceaf2;
// $gray-300: #dcd9e1;
// $gray-400: #cac8cf; //disable-bg
// $gray-500: #a8a7ac; //form-border
// $gray-600: #86858a;
// $gray-700: #656467;
// $gray-800: #434245;
// $gray-900: #212122;
// $black: #000;

//---blue gray----
$white: #fff;
$gray-100: #e7e7eb;
$gray-200: #cfcfd7;
$gray-300: #b7b6c2;
$gray-400: #9f9eae; //disable-bg
$gray-500: #87869a; //form-border
$gray-600: #6e6e86;
$gray-700: #565672;
$gray-800: #3e3d5d;
$gray-900: #262549;
$black: #0e0d35;

//---purple gray----
// $white: #fff;
// $gray-100: #F9F8FD;
// $gray-200: #ECEAF2;
// $gray-300: #DAD7E3;
// $gray-400: #C5C2D3; //disable-bg
// $gray-500: #9591B1; //form-border
// $gray-600: #716C94;
// $gray-700: #4D4772;
// $gray-800: #373353;
// $gray-900: #292938;
// $black: #000;

// GENERIC
$body-bg: #fff;

// 오퍼시티 색상
$f-b-1: rgba(0, 0, 0, 1);
$f-b-2: rgba(0, 0, 0, 0.85);
$f-b-3: rgba(0, 0, 0, 0.75); //selected-font
$f-b-4: rgba(0, 0, 0, 0.6);
$f-b-5: rgba(0, 0, 0, 0.45);
$f-b-6: rgba(0, 0, 0, 0.35); //placehold-font
$f-b-7: rgba(0, 0, 0, 0.25); //disable-font
$f-b-8: rgba(0, 0, 0, 0.15);
$f-b-9: rgba(0, 0, 0, 0.05);

$f-w-1: rgba(255, 255, 255, 1);
$f-w-2: rgba(255, 255, 255, 0.85); //selected-font
$f-w-3: rgba(255, 255, 255, 0.75);
$f-w-4: rgba(255, 255, 255, 0.6);
$f-w-5: rgba(255, 255, 255, 0.45);
$f-w-6: rgba(255, 255, 255, 0.35);
$f-w-7: rgba(255, 255, 255, 0.25);
$f-w-8: rgba(255, 255, 255, 0.15);
$f-w-9: rgba(255, 255, 255, 0.05);

//////////////////////////////////////////////////////
/* 2. 값 세팅 - form, layout, commons, content에 사용  */

/////////////////////////////////////////////////////
// 레이아웃 관련 값
// layout
$spacer-lg: 3rem;
$spacer-sm: 1.5rem;
$layout-spacer-lg: 3rem;
$layout-spacer-x: 1rem;
$logo-height: 23px;
$logo-width: 97px;

$app-header-height: 44px;
$app-footer-height: 20px;
$app-sidebar-width: 250px;
$app-sidebar-width-collapsed: 70px;

//폰트 관련 값
$headings-font-weight: 400;
$font-size-base: 12px; //0.88rem
$font-size-lg: ($font-size-base * 1.35);
$font-size-sm: ($font-size-base * 1.1);
$font-size-xs: ($font-size-base / 1.1);

//카드
$card-header-height: 40px;

//폼 관련 값
$form-height-small: 18px;
$form-height: 28px;
$form-height-big: 40px;

$form-radius: 2px;
$form-gap: 5px;
$form-left-padding: 0.5rem;

$form-bg-color: #f7f6f8; //테마에 덮여짐
$form-border-color: #ebeaed; //테마에 덮여짐
$form-disable-bg: $gray-200; //테마에 덮여짐
$form-txt: $f-b-1; //테마에 덮여짐
$form-disable-txt: $f-b-5; //테마에 덮여짐
$form-placeholder-txt: $f-b-5; //테마에 덮여짐

//쉐도우
$layout-shadow: 0 0 1rem rgba(30, 10, 10, 0.15);
$form-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
$card-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03),
  0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03),
  0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05),
  0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03);
$form-active-shadow: 0px 0px 10px rgba(255, 50, 50, 0.2);

//트렌지션
$form-transition: all 0.2s;

//반응형 관련 값
$breakpoint-mobile: 570px;
$breakpoint-tablet: 758px;
$breakpoint-labtop: 1024px;
$breakpoint-desktop: 1440px;
$breakpoint-widemonitor: 1680px;

// layout
//$spacer-lg: 3rem;
//$spacer-sm: 1.5rem;
//$layout-spacer-lg: 3rem;
//$layout-spacer-x: 1.5rem;
//$app-sidebar-width: 280px;
//$app-sidebar-width-collapsed: 80px;
//$logo-height: 23px;
//$logo-width: 97px;
//$app-header-height: 60px;
//$layout-shadow: 0 0 1rem rgba(30, 10, 10, 0.15);

//대시보드 편집 배경
$dashboard-edit-bg: #393986;