:root {
    --black: #000;
    --font-black: #343a40;
    --org: #f77200;
    --blur-org: #fff4e0;
    --blue: #545cd8;
    --blur-blue: #c5d1fe;
}

.btn-org {
    color: #fff !important;
    background-color: var(--org) !important;
    border-color: var(--org) !important;
}

.theme-light {
    .btn-org {
        color: #fff !important;
        background-color: var(--org) !important;
        border-color: var(--org) !important;
    }
}

.flex-center {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.disabled {
    cursor: not-allowed;
    button, input {
        pointer-events: none;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.overflow-visible {
    overflow: visible !important;
}

.app-container.background-color-unset {
    background-color: unset;
}

.close > span {
    vertical-align: text-top;
}

///////////////////temp
.custom-asset-marker {
    width: 15px;
    height: 15px;
    transform: translate(7.5px, 7.5px);
    background-color: #3076c1;
    border-radius: 50%;
}

//card-tab
.card-tab{
    background-color: #6070AA;
    & li{
        background-color: #99A6D3;
    }
}
