//////////////////////////////
/* Css link*/
@import '../font';
@import '../tools';
//////////////////////////////



.page-landering{
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: auto;
    & * {color: white !important;}

    & .page-landering__cont-wrap{
        width: 100%;
        height: 100%;
        //모바일헤더
        & .lander-mobile-header{ display: none; }
        //왼쪽 인포영역
        & .lander-info{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 55%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            & .lander-info__wrap{
                padding-top: 5%;
                margin-right: 10%;
                width: 70%;
                min-height: 60%;
                max-width: 600px;
                min-width: 300px;
                @media screen and (max-width:$breakpoint-labtop) {
                    margin: 0;
                    width: 100%;
                    padding: 0 2rem;
                }
                & .info--logo{
                    display: inline-block;
                    padding: .6rem .8rem .7rem 1.2rem;
                    border-radius: 5rem;
                    background-color: $default-brand;
                    transform: scale(.7) translateX(-20%);
                    margin-bottom: 1rem;
                    box-shadow: $card-shadow;
                    & .pnt-logo{opacity: .9;}
                }
                & .info--txt{
                    margin-bottom: 3rem;
                    & * {display: block;}
                    & .txt__main{
                        @include cover-b(1.1);
                        margin-bottom: 5rem;

                        @media (min-width:$breakpoint-mobile) and (max-width:$breakpoint-labtop){
                            @include title-b(1.2);
                        }
                        @media (max-height: $breakpoint-mobile){
                            @include h1-b();
                            margin-bottom: .8rem;
                        }
                    }
                    & .txt__sub{
                        @include h5-n;
                        opacity: .9;
                        margin-bottom: 1rem;

                        @media (max-width: $breakpoint-labtop){
                            @include p2-b();
                            margin-bottom: .6rem;
                        }
                        @media (max-height: $breakpoint-tablet){
                            @include p2-b();
                            margin-bottom: .6rem;
                        }
                    }
                    & .txt__desc{
                        @include p3-l;
                        opacity: .7;

                        @media (max-width: $breakpoint-labtop){
                            @include p4-l();
                        }
                        @media (max-height: $breakpoint-tablet){
                            @include p4-l();
                        }
                    }
                }
                & .info--login-btn{
                    display: none;
                }
            }
        }
        //오른쪽 로그인영역
        & .lander-login{
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            width: 45%;
            display: flex;
            align-items: center;
            background-color: rgba(0,0,0,0.5);

            & .lander-login__wrap{
                display: flex;
                //padding-top: 6%;
                margin-left: 10%;
                width: 70%;
                //min-height: 60%;
                max-width: 400px;
                min-width: 300px;
                @media screen and (max-width:$breakpoint-labtop) {
                    margin: 0;
                    width: 100%;
                    max-width: unset;
                    min-width: unset;
                    padding: 0 2rem;
                }
                & .title-box{
                    flex: 0 0 auto;
                    margin-bottom: 0;
                    & .txt__main{
                        @include h1-b();
                        white-space: nowrap;
                        margin-bottom: 1rem;
                    }
                    & .txt__sub{ @include p3-l(1.4); }
                }
                & .cont-box{
                    flex: 1 0 auto;
                    & .cont--error p{
                        @include p3-b(1.4);
                        color: $default-danger !important;
                        margin: 0;
                    }
                    & .cont--txt{
                        align-items: center;
                        text-align: center;
                        & .txt__main{ @include h3-b(1.5);}
                        & .txt__sub{ @include p2-n; }
                    }
                    & input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    input:-webkit-autofill:active  {
                        transition: background-color 5000s ease-in-out 0s;
                        -webkit-transition: background-color 9999s ease-out;
                        -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
                        -webkit-text-fill-color: #fff !important;
                    }
                    & input:focus{
                        border: 1px solid rgba(255, 255, 255, 0.4) !important;
                        -webkit-box-shadow: 0 0 0 1000px rgba(255,255,255,0.1) inset !important;
                    }
                }
                & .bottom-box{
                    flex: 0 0 auto;
                    margin-top: 1.5rem;
                    & .bottom-box__wrap{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 1.5rem;
                        text-align: center;
                        & a, & button{
                            opacity: .7;
                            &:hover{
                                color: white;
                                opacity: 1;
                            }
                        }
                        & .sign-in{
                            &::before{
                                content: '';
                                width: 1px;
                                height: 1rem;
                                background-color: rgba(255, 255, 255, 0.295);
                                transform: translateY(20%);
                                display: inline-block;
                                margin-right: 1rem;
                            }
                        }
                        & .to-login{
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            & a, & button{
                                opacity: 1;
                                border-radius: 100rem;
                                border: 1px solid white !important;
                            }
                        }
                    }
                }
            }
        }
    }
    & .page-landering__bg{
        position: fixed;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        background-image: url('../../images/background_login.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left;

        animation-name: bg-moving;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 100s;

        -webkit-animation-name: bg-moving;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: alternate;
        -webkit-animation-duration: 100s;

        -moz-animation-name: bg-moving;
        -moz-animation-iteration-count: infinite;
        -moz-animation-direction: alternate;
        -moz-animation-duration: 100s;

        &::after{
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5);
        }
    }

    @keyframes bg-moving {
        from { background-position: left; }
        to { background-position: right; }
    }
    @-webkit-keyframes bg-moving {
        from { background-position: left; }
        to { background-position: right; }
    }
    @-moz-keyframes bg-moving {
        from { background-position: left; }
        to { background-position: right; }
    }


    //폼
    & .pnt-btn{
        &.btn-secondary{
            &:hover{
                background-color: $default-secondary;
                filter: brightness(1.2);
            }
        }
        &.form-disable{
            box-shadow: 0 0 0 1px $form-border-color inset;
            background-color: $form-disable-bg;
            color: $form-disable-txt;
            &:hover{ filter: none; }
            & span{ filter: unset !important; opacity: .4; }
        }
    }
    & .pnt-input--group{
        & .pnt-input{
            color: white !important;
            letter-spacing: .5px;
            box-shadow: $form-shadow;
            width: 100%;
            height: 100%;
            border: 1px solid transparent !important;
            background-color: rgba(255,255,255,0.2) !important;
            border-radius: $form-radius;
            padding: 0 $form-left-padding;
            box-sizing: border-box;
            transition: $form-transition;
            &::placeholder{
                color: white !important;
                @include p3-l;
                white-space: nowrap;
                font-size: 13px;
                opacity: .6;
            }
        }
        input:focus, input:active, input:focus-visible {
            filter: none !important;
            background-color: rgba(255,255,255,0.35);
            border: 1px solid $gray-600;
            outline : none;
            box-shadow: $form-shadow;
        }
        input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px rgba(255,255,255,0.35) inset;
        }
        & span{filter: invert(1); opacity: 1;}
    }
    & .react-select {
        width: 150px;
        & * {color: white;}

        & .react-select__control {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.2);
            & .select__btn {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
        & .react-select__menu {
            background-color: rgba(255, 255, 255, 0.3);
            & .react-select__menu-list {
                & .react-select__option {
                    font-weight: bold;
                    &:hover {
                        background-color: rgba($gray-100, 0.5) !important;
                    }
                    // Option item selected
                    &.react-select__option--is-selected {
                        background-color: rgba($gray-300, 0.7);
                    }
                }
            }

            & li:hover a {
                background-color: rgba(255, 255, 255, 0.5);
            }

            & li.on a {
                background-color: $default-secondary;
                color: white;
            }
        }
    }
    & .pnt-checkbox{
        & .checkbox-effect{
            border: none !important;
            background-color: rgba(255,255,255,0.2) !important;
        }
        & p { color: white; }
        &.check-checked{
            & .checkbox-effect{
                background-color: $default-brand !important;
                border: none !important;
            }
        }
    }
    //폼 - 디스에이블
    & .form-disable{
        &.pnt-btn{
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            background-color: $gray-500 !important;
            color: white;
            & span{ filter: invert(1) brightness(2) !important; }
            &:hover{ filter: none !important; }
        }
        &.pnt-input--group{
            & .pnt-input{
                color: white !important;
                letter-spacing: .5px;
                box-shadow: $form-shadow;
                width: 100%;
                height: 100%;
                border: 1px solid transparent !important;
                background-color: rgba(255,255,255,0.2) !important;
                border-radius: $form-radius;
                padding: 0 $form-left-padding;
                box-sizing: border-box;
                transition: $form-transition;
                &::placeholder{
                    color: white !important;
                    @include p3-l;
                    white-space: nowrap;
                    font-size: 13px;
                    opacity: .6;
                }
            }
            input:focus, input:active, input:focus-visible {
                filter: none !important;
                background-color: rgba(255,255,255,0.35);
                border: 1px solid $gray-600;
                outline : none;
                box-shadow: $form-shadow;
            }
            & span{filter: invert(1); opacity: 1;}
        }
    }
}




@media (max-width:$breakpoint-mobile){
    $mobile-header-height: 56px;
    & .page-landering{
        overflow: hidden;
        & .page-landering__cont-wrap{
            &.login_clicked{
                background-color: rgba(0,0,0,0.4);
                & .lander-mobile-header{ display: none; }
                & .lander-info{ display: none; }
                & .lander-login{ display: block; }
            }
            //모바일헤더
            & .lander-mobile-header{
                display: block;
                z-index: 10;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                height: $mobile-header-height;
                & .mobile-header__wrap{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 100%;
                    padding: 0 1rem;
                    & .logo-box{
                        display: inline-block;
                        padding: .6rem .8rem .7rem 1.2rem;
                        border-radius: 5rem;
                        border: 1px solid white;
                        transform: scale(.5) translateX(-40%) translateY(35%);
                        margin-bottom: 1rem;
                        box-shadow: $card-shadow;
                        & .pnt-logo{opacity: 1;}
                    }
                    & .language-box{
                        & .select__options{
                            background-color: $gray-800;
                            left: unset;
                            right: 0;
                            transform: unset;
                        }
                    }
                }
            }
            //왼쪽 인포영역
            & .lander-info{
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                & .lander-info__wrap{
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    padding: 1.5rem;
                    display: flex;
                    align-items: center;
                    & .info--logo{
                        display: none;
                    }
                    & .info--txt{
                        padding: 15% 1rem;
                        & .txt__main{
                            @include h1-b();
                            margin-bottom: 3rem;
                            position: relative;
                        }
                        & .txt__sub{
                            display: block;
                            @include p2-b;
                            margin-bottom: 1rem;
                            &::before{
                                content: '';
                                width: 1.6rem;
                                height: 4px;
                                background-color: white;
                                display: block;
                                border-radius: 100rem;
                                margin-bottom: .5rem;
                            }
                        }
                        & .txt__desc{
                            @include p4-l;
                        }
                    }
                    & .info--language{
                        display: none;
                    }
                    & .info--login-btn{
                        display: block;
                        position: absolute;
                        right: 1.5rem;
                        bottom: 10%;
                    }
                }
            }
            //오른쪽 로그인영역
            & .lander-login{
                display: none;
                position: relative;
                width: 100%;
                height: 100%;

                & .lander-login__wrap{
                    margin: 0;
                    padding: 2rem;
                    padding-top: 30%;
                    height: 100%;
                    width: 100%;
                    & .title-box{
                        text-align: center;
                        & .txt__main{  @include h3-b(); }
                        & .txt__sub{ @include p3-l(1.4); }
                    }
                }
            }
        }
    }
}

//로그인 화면에서 보여지는 모달
.pnt-modal{
    .modal-body{
        background-color: white;
    }
}