@import "../_font";
@import "../variables";

.widgetCounts{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width : 100%;
}
.title{
  margin: 0;
  padding: 0;
  @include p3-n(12px);
}
.subTitle{
  margin: 0;
  padding: 0;
  @include p5-n(8px);
}

.widgetItemWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding : 10px 20px 20px;
  border-bottom: 1px solid $gray-200;
  background-color: $gray-100;
}
 .widgetName{
  color : $gray-700 !important;
  font-weight: 700 !important;
}

 .widgetDesc{
  color : $gray-500 !important;
}

