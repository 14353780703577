//////////////////////////////
/* Css link*/
@import "_font";
@import "_variables";

///////////////////////////////////////////////
// form css 모음 (업데이트에만 수정할 것)
///////////////////////////////////////////////

//---------------------------------------------------------------------------
//1. 버튼
@mixin btn-effect-black {
  color: $form-txt;
  & .material-icons-round {
    color: $form-txt;
  }
  &:hover {
    filter: brightness(0.9);
    color: $form-txt;
  }
}
@mixin btn-effect-white {
  color: white;
  & .material-icons-round {
    color: white;
  }
  &:hover {
    filter: brightness(1.2);
  }
}
.pnt-btn {
  @media (max-width: $breakpoint-mobile) {
    &.btn-long {
      width: 100%;
    }
  }
  //기본 세팅
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  position: relative;
  min-width: 4.5rem; //한글 두글자, 영어 4단어 까지 가능
  padding-left: $form-left-padding;
  padding-right: $form-left-padding;
  border-radius: $form-radius;
  background-color: transparent;
  border: none !important;

  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400 !important;
  transition: $form-transition;
  &:hover {
    color: $default-brand;
    & .material-icons-round {
      color: $default-brand;
    }
  }
  &.btn-shadow {
    box-shadow: 0 5px 10px rgba($gray-500, 0.3);
  }
  &[class*="btn-icon"] {
    & .material-icons-round {
      font-size: 18px;
    }
    &.form-h-small .material-icons-round {
      font-size: 12px;
    }
    &.form-h-big .material-icons-round {
      font-size: 24px;
    }
  }

  //---[옵션]아이콘 유무---[디폴트]노멀
  &.btn-icon {
    min-width: 4.7rem;
  }
  &.btn-icon-only {
    padding: 0;
    transition: $form-transition;
    width: $form-height;
    min-width: $form-height;
    &.form-h-small {
      width: $form-height-small;
      min-width: $form-height-small;
    }
    &.form-h-big {
      width: $form-height-big;
      min-width: $form-height-big;
    }
  }
  //---[옵션]사이즈
  &.form-h-small {
    gap: 5px;
    min-width: unset;
  }
  &.form-h-big {
    padding: 0 1rem;
  }
  //---[옵션] 컬러---
  &.btn-brand {
    @include btn-effect-white();
    background-color: $default-brand;
    &.btn-shadow {
      box-shadow: 0 5px 10px rgba($default-brand, 0.3);
    }
  }
  &.btn-secondary {
    @include btn-effect-white();
    background-color: $default-secondary;
    &.btn-shadow {
      box-shadow: 0 5px 10px rgba($default-secondary, 0.3);
    }
  }
  &.btn-danger {
    @include btn-effect-white();
    background-color: $default-danger;
    &.btn-shadow {
      box-shadow: 0 5px 10px rgba($default-danger, 0.3);
    }
  }
  &.btn-lightgray {
    @include btn-effect-black();
    background-color: $gray-300;
    &.btn-shadow {
      box-shadow: 0 5px 10px rgba($gray-300, 0.3);
    }
  }
  &.btn-gray {
    @include btn-effect-white();
    background-color: $gray-500;
    &.btn-shadow {
      box-shadow: 0 5px 10px rgba($gray-500, 0.3);
    }
  }
  &.btn-darkgray {
    @include btn-effect-white();
    background-color: $gray-700;
    &.btn-shadow {
      box-shadow: 0 5px 10px rgba($gray-700, 0.3);
    }
  }
  &.btn-line {
    background-color: transparent;
    border: 1px solid black !important;
    font-weight: 700 !important;
    &.btn-shadow {
      box-shadow: 0 5px 10px rgba($gray-500, 0.3);
    }
    &:hover {
      color: $default-brand;
      border: 1px solid $default-brand !important;
    }
  }
  &.btn-trans {
    background-color: transparent;
    border: none !important;
    font-weight: 700 !important;
    &:hover {
      color: $default-brand;
    }
  }
}

//2. 인풋[텍스트]
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px $form-bg-color inset;
}
.pnt-input--group {
  position: relative;
  min-width: 8rem;
  width: 18rem;
  & .pnt-input {
    width: 100%;
    height: 100%;
    border: 1px solid $form-border-color;
    background-color: $form-bg-color;
    border-radius: $form-radius;
    padding: 0 $form-left-padding;
    box-sizing: border-box;
    transition: $form-transition;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    &::placeholder {
      white-space: nowrap;
      font-weight: 400;
    }
  }
  input:focus,
  input:active,
  input:focus-visible {
    filter: none !important;
    background-color: $form-bg-color;
    border: 1px solid $gray-600;
    outline: none;
    box-shadow: $form-shadow;
  }
  & .input-error-txt,
  button {
    display: none;
  }
  //사이즈
  &.form-h-small {
    & input {
      padding: 5px;
    }
    &.btn-on-right button {
      transform: translateY(-30%) scale(0.6);
    }
  }
  &.form-h-big {
    & input {
      padding: 10px;
    }
    &.btn-on-right button {
      transform: translateY(-40%) translateX(-5px) scale(1);
    }
  }
  //옵션
  &.btn-on-right button {
    position: absolute;
    transform: translateY(-43%) translateX(-2px) scale(0.8);
    top: 50%;
    right: 0;
    display: block;
  }
  &.input-error {
    margin-bottom: 1rem;
    text-align: left;
    & .input-error-txt {
      display: block;
      font-size: 12px !important;
      font-weight: 500;
      color: $default-danger;
      margin: 4px 0 0 1px;
    }
    & input {
      border: 1px solid $default-danger;
      box-shadow: $form-shadow;
      &:hover {
        filter: none !important;
      }
    }
  }
  //disable
  &.form-disable {
    input:focus,
    input:active {
      border: 1px solid transparent !important;
      box-shadow: none !important;
    }
  }


  & .rbt {
    height: 100%;
    & .rbt-input-hint-container {
      height: 100%;
      & .pnt-input {
        background-color: $form-disable-bg;
        color: $form-disable-txt;
        filter: none;

        &::placeholder {
          color: $f-b-7;
        }

        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}

//3. 인풋[파일]
.pnt-file--group {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: $form-height;
  & input[type="file"] {
    background-color: $form-bg-color;
    border: 1px solid $form-border-color;
    box-shadow: none;
    outline: none;
    margin-left: -57px;
    width: 100%;
    height: 100%;
    position: relative;
    &::before {
      content: "";
      width: 10px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 56px;
      display: block;
      background-color: $form-bg-color;
      border-left: 1px solid $form-border-color;
    }
  }
  & label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 100%;
    margin: 0;
    padding: 0 0.8rem;
    background-color: white;
    border: 1px solid $form-border-color;
    border-left: none;

    color: $default-secondary;
    font-weight: 500;
    font-size: 13px;
    white-space: nowrap;
    & .material-icons-round {
      font-size: 18px;
      color: $default-secondary;
    }
  }
  //사이즈
  &.form-h-big {
    height: $form-height-big;
    & input[type="file"] {
      line-height: 30px;
    }
  }
  &.form-h-small {
    height: $form-height-small;
    & input[type="file"] {
      line-height: 10px;
      font-size: 12px;
      padding-left: 10px;
    }
    & label {
      font-size: 12px;
      & .material-icons-round {
        font-size: 12px;
      }
    }
  }
}

//4. 셀렉트
//리액트 컴포넌트 사용중이기에 스타일만 관리필요함.
//.pnt-select--group {
//  position: relative;
//  width: 9rem;
//  @media (max-width: $breakpoint-mobile) {
//    width: 100%;
//    max-width: unset;
//  }
//  //버튼부분
//  & .select__btn {
//    display: flex;
//    align-items: center;
//    width: 100%;
//    height: 100%;
//    padding: 0.5rem;
//    padding-left: $form-left-padding;
//    background-color: $form-bg-color;
//    border: 1px solid $form-border-color;
//    border-radius: $form-radius;
//    position: relative;
//    transition: $form-transition;
//    & .cont-wrap {
//      display: flex;
//      align-items: center;
//      width: 100%;
//      //padding-right: 1.5rem;
//      overflow: hidden;
//      white-space: nowrap;
//      text-overflow: ellipsis;
//      text-align: left;
//      //앞 아이콘
//      & .material-icons-round {
//        //margin-right: 5px;
//        font-size: 18px;
//        display: inline-block;
//        vertical-align: middle;
//      }
//    }
//    & > .material-icons-round {
//      position: absolute;
//      right: 0;
//      transform: translateY(-1px) translateX(3px) scaleX(0.9);
//      opacity: 0.6;
//    }
//  }
//  //ul 드롭다운 부분
//  & .select__options {
//    z-index: 10;
//    position: absolute;
//    top: calc(100% - 1px);
//    left: 0;
//    width: 100%;
//    padding: 0;
//    margin: 0;
//    border: 1px solid transparent;
//    border-radius: $form-radius;
//    box-shadow: $form-shadow;
//    overflow: hidden;
//    background-color: white;
//    transition: padding 0.2s;
//    opacity: 0;
//    & ul{
//      margin: 0;
//      padding: 0;
//    }
//    & li {
//      & a {
//        min-height: $form-height;
//        display: flex;
//        align-items: center;
//        padding: 0;
//        line-height: 0;
//        border-radius: $form-radius;
//        border-top: 1px solid $form-border-color;
//        background-color: transparent;
//        color: $black;
//        text-transform: capitalize;
//        transition: $form-transition;
//        &:last-child {
//          border: none;
//        }
//        &:hover a {
//          background-color: $gray-100;
//          transition: $form-transition;
//        }
//        &.on a {
//          background-color: $gray-300;
//          color: $default-secondary;
//        }
//      }
//    }
//  }
//  //활성화 일때
//  &.on {
//    box-shadow: $form-shadow;
//    & .select__btn {
//      filter: none !important;
//      border: 1px solid $gray-600;
//      box-shadow: $form-shadow;
//      transition: $form-transition;
//    }
//    & .select__options {
//      opacity: 1;
//      max-height: 300px;
//      overflow-y: auto;
//      transition: padding 0.2s;
//      & li a{
//        padding: 0.4rem 0.5rem;
//      }
//    }
//  }
//  //옵션
//  &.icon-on-left {
//    width: 9rem;
//  }
//  &.select-icon-only {
//    width: 44px;
//    min-width: unset;
//    & .select__btn {
//      padding: 0.3rem;
//      & .cont-wrap {
//        overflow: visible;
//      }
//    }
//    & .select__options {
//      width: 10rem;
//      left: 50%;
//      transform: translateX(-50%);
//    }
//  }
//  //사이즈
//  &.form-h-small {
//    & .cont-wrap > .material-icons-round {
//      font-size: 14px;
//    }
//    &.select-icon-only {
//      width: 36px;
//    }
//  }
//  &.form-h-big {
//    & .cont-wrap > .material-icons-round {
//      font-size: 26px;
//    }
//    & .select__btn > .material-icons-round {
//      font-size: 36px;
//    }
//    &.select-icon-only {
//      width: 60px;
//    }
//    & .select__options li a {
//      padding: 1.5rem 0.5rem;
//    }
//  }
//}

//5. 체크박스
.pnt-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin: 0 5px 0 0;
  & p {
    margin: 0 0 0 0.5rem;
    padding: 0;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    opacity: 0.8;
  }
  & input {
    display: none;
  }
  & .checkbox-effect {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: transparent;
    border: 1px solid $form-border-color;
    border-radius: $form-radius;
    overflow: hidden;
    &::before,
    &::after {
      content: "";
      width: 0;
      height: 2.5px;
      border-radius: 100rem;
      background: white;
      position: absolute;
      transform-origin: 0 0;
    }
    &::before {
      transform: rotate(45deg) translateX(-50%) translateY(-50%);
      top: calc(50% + 2.5px);
      left: calc(50% - 2.5px);
      transition: width 50ms ease 50ms;
    }
    &::after {
      transform: rotate(305deg) translateX(-50%) translateY(-50%);
      top: calc(50% + 0px);
      left: calc(50% + 2px);
      transition: width 50ms ease;
    }
  }
  &:has(input:checked) {
    & p {
      opacity: 1;
    }
    & .checkbox-effect {
      border: 1px solid transparent;
      background-color: $default-secondary;
      &::before {
        width: 6px;
        transition: width 100ms ease;
      }
      &::after {
        width: 12px;
        transition: width 150ms ease 100ms;
      }
    }
  }
}

//6. 토글
.pnt-toggle {
  position: relative;
  display: inline-block;
  width: 3rem;
  margin: 0;
  user-select: none;
  & p,
  div {
    display: none;
  }
  & input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: $default-success;
      &::before {
        transform: translateX(26px) translateY(-50%);
      }
    }
  }
  & .slider {
    background-color: $gray-300;
    border-radius: $form-radius;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    transition: 0.4s;
    &::before {
      z-index: 2;
      background-color: white;
      box-shadow: $form-shadow;
      content: "";
      position: absolute;
      left: 3px;
      top: 50%;
      transform: translateY(-50%);
      height: 16px;
      width: 16px;
      border-radius: 100rem;
      transition: 0.4s;
    }
  }
  & p {
    &:nth-of-type(1) {
      color: $f-b-1;
    }
    &:nth-of-type(2) {
      color: $f-b-1;
    }
  }
  //옵션
  &.toggle-txt {
    & p {
      z-index: 1;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-55%);
      margin: 0;
      &:nth-of-type(1) {
        color: gray;
        right: 5px;
        opacity: 0.8;
        transition: 0.2s;
      }
      &:nth-of-type(2) {
        color: white;
        left: 3px;
        opacity: 0;
        transition: 0.2s;
      }
    }
    & input:checked + span + p {
      opacity: 0;
    }
    & input:checked + span + p + p {
      opacity: 0.8;
    }
  }
  //옵션
  &.toggle-txt-long {
    width: 6rem;
    min-width: 6rem;
    & p {
      z-index: 1;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-55%);
      margin: 0;
      &:nth-of-type(1) {
        color: gray;
        right: 5px;
        opacity: 0.8;
        transition: 0.2s;
      }
      &:nth-of-type(2) {
        color: white;
        left: 3px;
        opacity: 0;
        transition: 0.2s;
      }
    }
    & input:checked + .slider {
      background-color: $default-success;
      &::before {
        transform: translateX(4.6rem) translateY(-50%);
      }
    }
    & input:checked + span + p {
      opacity: 0;
    }
    & input:checked + span + p + p {
      opacity: 0.8;
    }
    &.form-h-big {
      width: 7rem;
      min-width: 7rem;
      & input:checked + .slider {
        &::before {
          transform: translateX(5.2rem) translateY(-50%);
        }
      }
    }
  }
  //현재 안쓰는 옵션
  &.toggle-icon {
    & div {
      z-index: 1;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-55%) scale(0.8);
      margin: 0;
      filter: invert(1);
      &:nth-of-type(1) {
        right: 5px;
        opacity: 0.8;
        transition: 0.2s;
      }
      &:nth-of-type(2) {
        left: 3px;
        opacity: 0;
        transition: 0.2s;
      }
    }
    & input:checked + span + p + p + div {
      opacity: 0;
    }
    & input:checked + span + p + p + div + div {
      opacity: 0.8;
    }
  }
}

//7. 라디오
.pnt-radio {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  height: $form-height;
  min-width: 6rem;
  background-color: transparent;
  border: 1px solid $form-border-color;
  border-radius: $form-radius;
  padding: 0 $form-left-padding;
  & p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
  }
  & input {
    appearance: none;
  }
  & input {
    vertical-align: middle;
    appearance: none;
    border: 1px solid $gray-400 !important;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: border 0.2s ease-in-out;
    margin: 0 !important;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 2px solid white !important;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    &:hover {
      cursor: pointer;
    }
  }
  //체크시
  &:has(input:checked) {
    border: 1px solid $default-brand;
    & input {
      border: 0.5em solid $default-brand !important;
    }
  }
  //비활성화시
  &:has(input:disabled) {
    background-color: $form-disable-bg;
    & p {
      color: $form-disable-txt;
    }
    & input {
      cursor: not-allowed;
      &::after {
        border: 2px solid transparent !important;
      }
    }
  }
  //사이즈
  &.form-h-small,
  &.form-h-big {
    width: auto;
    min-width: unset;
  }
}

//8. 네임플레이트
.pnt-nameplate {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 0.5rem;
  border-radius: $form-radius;
  background-color: $gray-200;
  color: black;
  max-width: 8rem;
  position: relative;
  & span {
    font-size: 18px;
  }
  & .cont-wrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

//9. 텍스트
.pnt-txt {
  margin: 0;
  padding: 0;
  &.s-10 {
    @include p5-n();
  }
  &.s-9 {
    @include p4-n();
  }
  &.s-8 {
    @include p3-n();
  }
  &.s-7 {
    @include p2-n();
  }
  &.s-6 {
    @include p1-n();
  }
  &.s-5 {
    @include h5-n();
  }
  &.s-4 {
    @include h4-n();
  }
  &.s-3 {
    @include h3-n();
  }
  &.s-2 {
    @include h2-n();
  }
  &.s-1 {
    @include h1-n();
  }
  &.txt-light {
    font-weight: 300;
  }
  &.txt-bold {
    font-weight: 700;
  }
  &.txt-border {
    border: 1px solid $form-border-color;
    padding: 0 0.5rem;
    border-radius: $form-radius;
    margin: 0;
    white-space: nowrap;
    display: inline-block;
  }
  &.txt-dot {
    display: block;
    width: 100%;
    &::before {
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 100px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.4rem;
      background-color: $gray-700;
    }
  }
  &.txt-bg {
    padding: 0.1rem 0.5rem;
    border-radius: $form-radius;
    margin: 0;
    background-color: $gray-100;
  }
}

//10. 라벨
ul,
div,
li {
  &.pnt-label-1 {
    & .label-main {
      min-width: unset;
      width: 3rem;
      flex-shrink: 0;
    }
  }
  &.pnt-label-2 {
    & .label-main {
      min-width: unset;
      width: 4rem;
      flex-shrink: 0;
    }
  }
  &.pnt-label-3 {
    & .label-main {
      width: 5rem;
      flex-shrink: 0;
    }
  }
  &.pnt-label-4 {
    & .label-main {
      width: 6rem;
      flex-shrink: 0;
    }
  }
  &.pnt-label-5 {
    & .label-main {
      width: 7rem;
      flex-shrink: 0;
    }
  }
  &.pnt-label-6 {
    & .label-main {
      width: 8rem;
      flex-shrink: 0;
    }
  }
  &.pnt-label-7 {
    & .label-main {
      width: 9rem;
      flex-shrink: 0;
    }
  }
  &.pnt-label-8 {
    & .label-main {
      width: 10rem;
      flex-shrink: 0;
    }
  }
  &.pnt-label-9 {
    & .label-main {
      width: 11rem;
      flex-shrink: 0;
    }
  }
  &.pnt-label-10 {
    & .label-main {
      width: 12rem;
      flex-shrink: 0;
    }
  }
  //위 정렬
  &.label-flx-start {
    & .pnt-label--group {
      align-items: flex-start;
    }
  }
  //상하정렬
  &.label-flx-col {
    & .pnt-label--group {
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;
      & .label-main {
        width: 100% !important;
        flex-shrink: 0;
      }
    }
  }
}
.pnt-label--group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $form-gap;
  position: relative;

  @at-root .label-main {
    display: flex;
    align-items: center;
    gap: 8px;
    min-width: 5rem;
    overflow: visible;
    word-break: keep-all;
    text-align: left;
    text-transform: capitalize;
    position: relative;
    font-weight: 700;
    @media (max-width: $breakpoint-tablet) {
      width: 6rem;
      flex-shrink: 0;
    }
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      align-items: flex-start;
      & .label-main {
        width: 100% !important;
        flex-shrink: 0;
      }
    }
  }
  & .label-options {
    flex: 1 1 auto;
    color: $form-txt;
    font-weight: 400 !important;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: $form-gap;
    position: relative;
  }
}

//11. 디바이더
.pnt-divider {
  margin: 0 0.5rem;
  width: 1.5px;
  height: $form-height-small;
  background-color: $gray-400;
  &.vertical-line {
    height: 100%;
    width: 1px;
    &.border-bold-1 {
      width: 1px;
    }
    &.border-bold-2 {
      width: 2px;
    }
    &.border-bold-3 {
      width: 3px;
    }
    &.border-bold-4 {
      width: 4px;
    }
    &.border-bold-5 {
      width: 5px;
    }
  }
  &.horizon-line {
    width: 100%;
    height: 1px;
    margin: 0.6rem 0;
    &.border-bold-1 {
      height: 1px;
    }
    &.border-bold-2 {
      height: 2px;
    }
    &.border-bold-3 {
      height: 3px;
    }
    &.border-bold-4 {
      height: 4px;
    }
    &.border-bold-5 {
      height: 5px;
    }
  }
}

//12_1. 테이블[페이지네이션]
.pagination {
  display: flex;
  justify-content: flex-end;
  & button .material-icons-round {
    font-size: 16px !important;
  }
  & .now {
    font-size: 13px;
    font-weight: 700;
    color: $form-txt;
    opacity: 0.8;
  }
  & .whole {
    font-size: 13px;
    color: $form-txt;
    opacity: 0.5;
  }
}
//12_2. 테이블
//리액트 컴포넌트 사용중이기에 스타일만 관리필요함.
$table-height: 30px;
.pnt-table {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  height: 300px;
  //클릭 못하는 단순 뷰용 테이블
  &.table-for-viewer {
    & .border-box .tbody .tr:hover {
      cursor: unset !important;
    }
  }
  //콜룸 하나짜리 테이블
  &.table-one-column {
    height: calc(#{$table-height} * 2);
  }
  & .th,
  & .td {
    display: flex;
    flex: 1 1 1%;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    text-align: left;
    padding: 5px 10px;
    border-right: 1px solid $gray-100;
    border-bottom: 1px solid $gray-100;
    line-height: 14px;

    &:last-child {
      border-right: none;
    }
  }
  & .border-box {
    overflow: auto hidden;
    border-top: 1.4px solid black;
    border-bottom: 1.4px solid black;
    height: 100%;
    & .thead {
      overflow: visible;
      & .tr {
        min-height: $table-height;
        display: flex;
        padding: 0;
        border-bottom: 1px solid black;
        & .th {
          background-color: #d2d2fd;
          font-weight: bold;
        }
      }
    }
    & .tbody {
      min-height: 10rem;
      height: calc(100% - #{$table-height});
      position: relative;
      background-color: white;
      & > div {
        padding: 0;
        overflow: visible;
        height: 100%;
        & > div {
          position: relative;
          height: 100%;
          width: 100%;
          overflow: overlay;
          will-change: transform;
          direction: ltr;
          & > div {
            & .tr {
              display: flex;
              width: 100%;
              height: $table-height;
              position: absolute;
              left: 0;
              &:hover {
                background-color: #ebecf3;
                cursor: pointer;
                transition: $form-transition;
              }
              &.active {
                background-color: $default-secondary;
                & .td {
                  color: black;
                }
              }
              & .td {
                font-weight: 400 !important;
              }
              &:nth-child(2n) {
                background-color: rgba(0, 0, 0, 0.02);
              }
              //임시 js
              &:nth-child(1) {
                top: 0;
              }
              &:nth-child(2) {
                top: calc(#{$table-height} * 1);
              }
              &:nth-child(3) {
                top: calc(#{$table-height} * 2);
              }
              &:nth-child(4) {
                top: calc(#{$table-height} * 3);
              }
              &:nth-child(5) {
                top: calc(#{$table-height} * 4);
              }
              &:nth-child(6) {
                top: calc(#{$table-height} * 5);
              }
              &:nth-child(7) {
                top: calc(#{$table-height} * 6);
              }
              &:nth-child(8) {
                top: calc(#{$table-height} * 7);
              }
              &:nth-child(9) {
                top: calc(#{$table-height} * 8);
              }
              &:nth-child(10) {
                top: calc(#{$table-height} * 9);
              }
              &:nth-child(11) {
                top: calc(#{$table-height} * 10);
              }
              &:nth-child(12) {
                top: calc(#{$table-height} * 11);
              }
              &:nth-child(13) {
                top: calc(#{$table-height} * 12);
              }
              &:nth-child(14) {
                top: calc(#{$table-height} * 13);
              }
              &:nth-child(15) {
                top: calc(#{$table-height} * 14);
              }
              &:nth-child(16) {
                top: calc(#{$table-height} * 15);
              }
              &:nth-child(17) {
                top: calc(#{$table-height} * 16);
              }
              &:nth-child(18) {
                top: calc(#{$table-height} * 17);
              }
              &:nth-child(19) {
                top: calc(#{$table-height} * 18);
              }
              &:nth-child(20) {
                top: calc(#{$table-height} * 19);
              }
            }
          }
        }
      }
    }
  }
  //옵션
  &.table-align-center {
    & .th,
    & .td {
      justify-content: center !important;
      text-align: center !important;
    }
  }
}

///////////////////////////////////////////////
// form 기능 모음
///////////////////////////////////////////////

// 1. form의 disable 처리 (disable 기능 사용할수 있는 form은 전부 조절 가능)
.form-disable {
  filter: none !important;
  cursor: default !important;
  &:hover {
    cursor: default !important;
  }
  //버튼
  &.pnt-btn {
    box-shadow: 0 0 0 1px $form-border-color inset;
    background-color: $form-disable-bg;
    color: $form-disable-txt;
    &:hover {
      filter: none;
    }
    & span {
      filter: unset !important;
      opacity: 0.4;
    }
  }
  //인풋
  &.pnt-input--group {
    & .pnt-input {
      background-color: $form-disable-bg;
      color: $form-disable-txt;
      filter: none;
      &::placeholder {
        color: $f-b-7;
      }
      &:hover {
        box-shadow: none;
      }
    }
  }
  //인풋[파일]
  &.pnt-file--group {
    & input[type="file"] {
      background-color: $form-bg-color;
      border: 1px solid $form-border-color;
      color: $form-disable-txt !important;
      &::before {
        background-color: $form-bg-color;
        border-left: 1px solid $form-border-color;
      }
    }
    & label {
      background-color: $form-disable-bg;
      border: 1px solid $form-border-color;
      color: $form-disable-txt;
      & .material-icons-round {
        color: $form-disable-txt;
      }
    }
  }
  //셀렉트
  &.pnt-select--group {
    &:hover {
      cursor: default !important;
    }
    & .select__btn {
      background-color: $form-disable-bg;
      box-shadow: none;
      & span {
        opacity: 0.4;
      }
      filter: none !important;
      &:hover {
        cursor: default !important;
      }
      & .cont-wrap {
        color: $form-disable-txt;
      }
    }
  }
  //체크박스
  &.pnt-checkbox {
    & .checkbox-effect {
      background-color: $form-disable-bg;
    }
    & p {
      opacity: 0.5;
    }
  }
  //토글
  &.pnt-toggle {
    & input:checked + .slider {
      background-color: $default-success;
    }
    & .slider {
      background-color: $gray-300;
      &::before {
        background-color: white;
      }
    }
  }
}

// 2. form의 must 처리 (오직 input, select 만 가능)
.form-must {
  &.pnt-select--group,
  &.pnt-input--group {
    position: relative;
    & input {
      border: 1px solid $default-brand;
    }
    & .select__btn {
      border: 1px solid $default-brand;
      &:hover {
        border: 1px solid orangered;
      }
    }
    &::after {
      display: block;
      content: "*";
      position: absolute;
      top: -0.8rem;
      left: -0.3rem;
      @include p1-n();
      color: $default-danger;
    }
  }
}

$form-default-font: 0.8rem;
$form-small-font: 0.7rem;
$form-big-font: 1.15rem;

// 3. form의 폰트 설정
.pnt-input--group input,
.pnt-input--group input::placeholder,
.pnt-select--group .cont-wrap,
.pnt-select--group a,
.pnt-checkbox p,
.pnt-nameplate .cont-wrap,
.pnt-btn,
.pnt-radio p,
.pnt-toggle p,
.pnt-txt,
.pnt-label--group .label-options {
  font-size: $form-default-font;
  font-weight: 500;
  color: $gray-800; //각 theme폴더에 의해 덮여지고 있음
}
.pnt-table--paging__wrap span,
.pnt-table .th,
.pnt-table .td {
  font-size: 0.9rem; //테이블만 특별하게 조금 큰 폰트 사용
}
.pnt-btn.form-h-small,
.pnt-input--group.form-h-small input,
.pnt-input--group.form-h-small input::placeholder,
.pnt-select--group.form-h-small .cont-wrap,
.pnt-select--group.form-h-small .select__options a,
.pnt-checkbox.form-h-small p,
.pnt-radio.form-h-small p,
.pnt-nameplate.form-h-small .cont-wrap {
  font-size: $form-small-font;
}
.pnt-btn.form-h-big,
.pnt-input--group.form-h-big input,
.pnt-input--group.form-h-big input::placeholder,
.pnt-select--group.form-h-big .cont-wrap,
.pnt-select--group.form-h-big .select__options a,
.pnt-checkbox.form-h-big p,
.pnt-radio.form-h-big p,
.pnt-nameplate.form-h-big .cont-wrap {
  font-size: $form-big-font;
}

// 4. form의 size 설정
.pnt-input--group,
.pnt-select--group,
.pnt-btn,
.pnt-checkbox,
.pnt-radio,
.pnt-nameplate,
.pnt-file--group {
  height: $form-height;
  &.form-h-small {
    height: $form-height-small;
  }
  &.form-h-big {
    height: $form-height-big;
  }
}

//토글과 체크박스는 다른 높이 설정을 가짐
//하지만 사용하는 class이름은 동일함(form-h-small, form-h-big)
$check-size-small: 16px;
$check-size: 20px;
.pnt-toggle {
  height: $check-size;
  &.form-h-small {
    height: $check-size-small;
    width: 2rem;
    & p {
      font-size: $form-small-font;
    }
    & input:checked + .slider::before {
      transform: translateX(1rem) translateY(-50%);
    }
    & .slider::before {
      width: 10px;
      height: 10px;
    }
  }
  &.form-h-big {
    height: $form-height;
    width: 4rem;
    & input:checked + .slider::before {
      transform: translateX(2.3rem) translateY(-50%);
    }
    & .slider::before {
      height: 20px;
      width: 20px;
    }
    & p {
      font-size: $form-big-font;
      &:nth-of-type(1) {
        right: 10px !important;
      }
      &:nth-of-type(2) {
        left: 10px !important;
      }
    }
  }
}
.pnt-checkbox {
  & .checkbox-effect {
    width: $check-size;
    height: $check-size;
  }
  &.form-h-small {
    & .checkbox-effect {
      width: $check-size-small;
      height: $check-size-small;
      &::before {
        transform: rotate(45deg) translateX(-50%) translateY(-80%) scale(0.8);
      }
      &::after {
        transform: rotate(305deg) translateX(-40%) translateY(-50%) scale(0.8);
      }
    }
  }
  &.form-h-big {
    & .checkbox-effect {
      width: $form-height;
      height: $form-height;
      &::before {
        transform: rotate(45deg) translateX(-80%) translateY(-80%) scale(1.5);
      }
      &::after {
        transform: rotate(305deg) translateX(-40%) translateY(-60%) scale(1.3);
      }
    }
  }
}

// 5. form의 hover 설정
.pnt-input--group input,
.pnt-select--group .select__btn,
.pnt-checkbox,
.pnt-toggle {
  transition: $form-transition;
  &:hover {
    filter: brightness(0.98);
    transition: $form-transition;
  }
}

// 5. 다크테마용 form
#root .app-container.app-theme-pnt-dark,
.app-container.app-theme-pnt-plant {
  & input:focus,
  input:active,
  input:focus-visible {
    border: 1px solid white;
  }
  & .form-disable {
    & input:focus,
    input:active,
    input:focus-visible {
      border: 1px solid $gray-700 !important; //dark 테마에서 $form-border와 동일한색 유지할것.
    }
  }
  & .pnt-select--group {
    & .select__options {
      border: none;
    }
  }
  & .pnt-btn.form-disable {
    border: 1px solid $form-border-color;
    & span {
      filter: invert(1) !important;
    }
  }
  & .pnt-label--group {
    & .label-line,
    .label-line-long {
      &::before {
        background-color: $gray-700;
      }
    }
    & span[class*="icon-"] {
      opacity: 0.5;
    }
  }
  & span[class*="icon-"] {
    filter: invert(1);
  }
  & .pnt-btn {
    & span[class*="icon-"] {
      filter: none;
    }
  }
  & .pnt-input--group {
    & span[class*="icon-"] {
      filter: invert(1);
    }
  }
}
