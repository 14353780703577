//////////////////////////////
/* Css link*/
@import "../default/_default";
@import "../../variables";

// ---------------------------------------------------------
// ※-------------------------------------------------------
// light 테마의 기본 색상은 하단에서 수정해주세요.
// $brand, $secondary, $success, $warning, $danger, $info, $gray, $light-gray, $dark-gray
// 위 9개 종류는 고정입니다.
// ---------------------------------------------------------

$brand: #ff8800;
$secondary: #6a55c0;
$success: #80d217;
$warning: #ffce2e;
$danger: #ff3358;
$info: #7fafa8;

$light-gray: rgb(229, 228, 238);
$gray: #9f9eae;
$dark-gray: #453e6f;

//-------------------------------------------------------------
// ※----------------------------------------------------------
// 하단 내용은 특별한 일이 없는 한 고정입니다.-------------------
//-------------------------------------------------------------

$brand-d4: mix($brand, black, 40%);
$brand-d3: mix($brand, black, 55%);
$brand-d2: mix($brand, black, 70%);
$brand-d1: mix($brand, black, 83%);
$brand-l4: mix($brand, white, 80%);
$brand-l3: mix($brand, white, 55%);
$brand-l2: mix($brand, white, 30%);
$brand-l1: mix($brand, white, 10%);

$secondary-d4: #282843;
$secondary-d3: #373062;
$secondary-d2: #4d4386;
$secondary-d1: #7169a6;
$secondary-l4: #958fbd;
$secondary-l3: #c5c1d9;
$secondary-l2: #dbd6e7;
$secondary-l1: #eceaf4;

$success-d4: mix($success, black, 40%);
$success-d3: mix($success, black, 55%);
$success-d2: mix($success, black, 70%);
$success-d1: mix($success, black, 83%);
$success-l4: mix($success, white, 82%);
$success-l3: mix($success, white, 60%);
$success-l2: mix($success, white, 35%);
$success-l1: mix($success, white, 18%);

$warning-d4: mix($warning, black, 40%);
$warning-d3: mix($warning, black, 58%);
$warning-d2: mix($warning, black, 75%);
$warning-d1: mix($warning, black, 90%);
$warning-l4: mix($warning, white, 75%);
$warning-l3: mix($warning, white, 55%);
$warning-l2: mix($warning, white, 35%);
$warning-l1: mix($warning, white, 20%);

$danger-d4: mix($danger, black, 40%);
$danger-d3: mix($danger, black, 55%);
$danger-d2: mix($danger, black, 70%);
$danger-d1: mix($danger, black, 83%);
$danger-l4: mix($danger, white, 75%);
$danger-l3: mix($danger, white, 50%);
$danger-l2: mix($danger, white, 30%);
$danger-l1: mix($danger, white, 10%);

$info-d4: mix($info, black, 40%);
$info-d3: mix($info, black, 55%);
$info-d2: mix($info, black, 70%);
$info-d1: mix($info, black, 83%);
$info-l4: mix($info, white, 75%);
$info-l3: mix($info, white, 50%);
$info-l2: mix($info, white, 30%);
$info-l1: mix($info, white, 10%);

$gray-d: mix($gray, black, 50%);
$gray-l: mix($gray, white, 30%);
$light-gray-d: mix($light-gray, black, 50%);
$light-gray-l: mix($light-gray, white, 30%);
$dark-gray-d: mix($dark-gray, black, 50%);
$dark-gray-l: mix($dark-gray, white, 30%);

$depth-base: white;
$depth-1: mix($depth-base, #e4e2ec, 95%);
$depth-2: mix($depth-base, #e4e2ec, 85%);
$depth-3: mix($depth-base, #e4e2ec, 75%);
$depth-4: mix($depth-base, #e4e2ec, 65%);
$depth-5: mix($depth-base, #e4e2ec, 55%);
$depth-6: mix($depth-base, #e4e2ec, 45%);
$depth-7: mix($depth-base, #e4e2ec, 30%);
$depth-8: mix($depth-base, #e4e2ec, 15%);
$depth-9: mix(#1f1f27, #e4e2ec, 10%);
$depth-10: mix(#1f1f27, #e4e2ec, 50%);

// ---------------------------------------------------------
// ※-------------------------------------------------------
// 하단의 내용은 수정할 수 있지만, 변수를 추가, 제거할 순 없습니다.
// 변수 추가, 제거를 원하면 먼저 css/theme/default/default/scss 에서 수정한뒤
// 각 theme 폴더안에 동일하게 적용해주세요.
// ---------------------------------------------------------

//웹 - 공통txt
$base-txt: $f-b-3;

//웹 - 바디
//(그라디언트만 있습니다. solid로 바꾸고 싶으면, gradient 둘다에 동일색을 넣으세요.)
$whole-body-gradient-start: $depth-5;
$whole-body-gradient-end: $depth-8;
$body-title-bg: $depth-base;

//대시보드 편집 배경
$dashboard-edit-bg: #393986;

//웹 - 헤더
$header-bg: $depth-base;
$header-logobox: #292938; //사이드바와 같은 색상
$header-menu-text1: $f-b-1;
$header-menu-text2: $secondary;
$header-widget-icon: gray;

//웹 - 사이드바
$menu-body-bg: #292938;
$menu-hover-bg: rgba(196, 181, 238, 0.2);
$menu-active-bg: rgba(196, 181, 238, 0.2);
$menu-line-color: #48455c;
$menu-icon-color: #a7a2c4;
$menu-down-icon-color: #706966;
$menu-main-txt: #a7a2c4;
$menu-sub-txt: $f-w-1;
$menu-active-txt: rgb(187, 170, 255);

$menu-icon-filter: invert(75%) sepia(16%) saturate(459%) hue-rotate(209deg) brightness(88%) contrast(80%);
$menu-icon-active-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);

//웹 - 푸터
$footer-bg: transparent;
$footer-txt: $f-b-5;

//모바일
$app-header-bg: $depth-base;

//카드
$card-body-bg: $depth-base;
$card-header-bg: white;
$card-header-border: $gray-200;
$card-icon-color: $secondary-l4;
$card-icon-filter: invert(0.4);
$card-title-color: $gray-900;
$card-tab: $depth-6;
$card-tab-li: $depth-4;
$card-tab-li-active: $depth-base;
$card-tab-txt: $f-b-1;
$card-tab-li-txt: $f-b-6;
$card-tab-2: $secondary;
$card-button-body: $depth-3;

//이너 아이템
$inner-card-body-bg: $depth-base;
$inner-card-header-bg: $depth-base;
$inner-card-border: $depth-base;

//테이블
$table-border: $gray-300;
$table-inner-border: rgba(0, 0, 0, 0.1);
$table-inner-bottom-border: rgba(0, 0, 0, 0.05);
$table-header-border: transparent;
$table-2row-bg: $depth-1;
$table-thead-bg: $depth-4;
$table-tbody-bg: $depth-base;
$table-hover-bg: $depth-5;
$table-clicked-bg: $depth-9;
$table-clicked-txt: $gray-900;
$table-th-txt: $gray-800;
$table-td-txt: #747375;

//라디오
$radio-checked-bg: $gray-800;

//토글
$toggle-bg: $depth-9;
$toggle-bar-bg: $depth-base;

//네임플레이트
$nameplate-bg: $depth-6;
$nameplate-txt: $gray-700;

//스크롤바
$scrollbar-bg: rgba(0, 0, 0, 0.2);
$scrollbar-track-bg: rgba(0, 0, 0, 0.1);

//form 기본
$form-bg: $depth-4;
$form-border: $depth-8;
$form-txt: $gray-700;

$form-active-bg: $secondary;
$form-active-border: $gray-700;
$form-active-txt: $gray-800;

$form-active-bg-light: $gray-300;
$form-active-txt-light: $secondary;

$form-disable-bg: $depth-8;
$form-disable-txt: $gray-500;

$form-placeholder-txt: $gray-400;
$form-hover-bg: $gray-200;

//인풋
$input-bg: $form-bg;
$input-border: $form-border;

//위젯
$widget-active-bg: $depth-base;
$widget-active-border: $secondary;
$widget-active-txt: $secondary;

//블랙화이트
$trans-black : black;

/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////

//적용
#root .app-container.app-theme-pnt-navy {
  @include theme-default();
}
