@import "../variables";
@import '../theme/default/default';

.item_count{
  padding: 0.25rem 0.4rem !important;
  min-width: 24px;
  text-align: center;
}
.item_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.25rem;
  padding: 0 0.3rem 0 0.1rem;
  & > span{
    transform: scale(0.8) !important;
    vertical-align: center;
  }
}
