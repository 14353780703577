//////////////////////////////
/* Css link*/
@import "../../_variables";

// ---------------------------------------------------------
// ---------------------------------------------------------
// ------※이 파일의 하단은 공통 컬러 레이아웃 입니다※-------
// ---------------------------------------------------------
// ---------------------------------------------------------

@mixin theme-default {
  $depth-list: (
    base:$depth-base,
    1:$depth-1,
    2:$depth-2,
    3:$depth-3,
    4:$depth-4,
    5:$depth-5,
    6:$depth-6,
    7:$depth-7,
    8:$depth-8,
    9:$depth-9,
    10:$depth-10
  );
  @each $suffix, $value in $depth-list {
    .color-depth-#{$suffix} {
      color: $value !important;
    }
    .bg-depth-#{$suffix} {
      background-color: $value !important;
    }
    .border-depth-#{$suffix} {
      border-color: $value !important;
    }
  }
  .border-size-1 {
    border-width: 1px !important;
    border-style: solid !important;
  }

  /////////////////////////////////////////////////////////
  //rms에 있는 클래스 입니다 (임시)
  .monitoring-img {
    background-color: $card-body-bg;
  }

  /////////////////////////////////////////////////////////
  //공통 글자
  color: $base-txt;
  & input,
  textarea,
  select,
  table,
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  div {
    color: $base-txt;
  }
  /////////////////////////////////////////////////////////
  //헤더 영역
  & .app-header__logo {
    background-color: $menu-body-bg; //사이드바와 같은 색상
  }
  & .app-header__content {
    background-color: $header-bg;
    & .app-header-left {
      & .menu-main {
        color: $header-menu-text1;
      }
      & .menu-sub {
        color: $header-menu-text2;
      }
    }
    & .app-header-right {
      & .material-icons-round {
        color: $header-widget-icon;
      }
    }
  }
  & .user-box .user-options {
    background-color: $depth-8;
    border: 1px solid $depth-8;
    & .setting-item {
      background-color: $depth-4;
      &:hover {
        background-color: $depth-6;
      }
    }
  }
  /////////////////////////////////////////////////////////
  //필터
  & .filter_ver5 {
    background-color: $depth-1;
    & .filter-box {
      border-top: 1px solid $depth-5;
    }
  }
  /////////////////////////////////////////////////////////
  //사이드바 영역
  //for React
  & .app-sidebar {
    background: $menu-body-bg;
    & .vertical-nav-menu > .metismenu-container > .metismenu-item {
      //메뉴 한세트
      border-top: 1px solid rgba(255, 255, 255, 0.098);
      //선택된 대 메뉴
      &.nav-child-active {
        & > a.metismenu-link {
          //메뉴 아이콘 customize
          & .metismenu-icon {
            -webkit-filter: $menu-icon-active-filter;
            filter: $menu-icon-active-filter;
          }
          & span, .material-icons-round {
            color: white;
          }
          // customize
          & .metismenu-state-icon {
            opacity: 1;
          }
        }
      }
      //대 메뉴
      & > a.metismenu-link {
        &:hover {
          background-color: $menu-hover-bg;
          //접기버튼
          & .material-icons-round {
            color: white;
          }
        }
        //메뉴 아이콘
        & .metismenu-icon.material-icons-round {
          color: $menu-icon-color;
        }

        & .metismenu-state-icon {
          color: $menu-sub-txt;
        }

        //대메뉴 타이틀
        & span {
          color: $menu-main-txt;
        }
        //접기버튼
        & .material-icons-round {
          color: $menu-down-icon-color;
        }
      }
      //소메뉴 세트
      & > ul.metismenu-container.visible {
        //소메뉴
        & > li > a.metismenu-link {
          background: transparent;
          &:hover {
            background-color: $menu-hover-bg;
          }
          & span {
            color: $menu-sub-txt;
          }
          &.active {
            background: $menu-active-bg;
            & span {
              color: $menu-active-txt;
            }
          }
        }
        &::before {
          background-color: $menu-line-color;
        }
      }

      //메뉴 아이콘 customize
      & .metismenu-icon {
        -webkit-filter: $menu-icon-filter;
        filter: $menu-icon-filter;
      }
    }
  }
  /////////////////////////////////////////////////////////
  //바디 영역
  & .app-main__inner,
  & .app-main__outer {
    background: linear-gradient(
      $whole-body-gradient-start,
      $whole-body-gradient-end
    );
  }
  &.dashboard-edit-mode {
    & .app-header .app-header__content {
      background-color: $dashboard-edit-bg;
    }
    @media (max-width: $breakpoint-labtop) {
      & .app-header {
        background-color: $dashboard-edit-bg;
      }
    }
    & .dashboard-edit-title {
      background-color: $dashboard-edit-bg;
    }
  }
  & .app-page-title {
    background: $body-title-bg;
  }
  & .app-page-content {
    border-top: 1px solid $card-header-border;
  }
  & .app-footer {
    background-color: $footer-bg;
    & .footer-wrap {
      color: $footer-txt;
    }
  }
  //카드
  & .card {
    background-color: $card-body-bg;
    & .card-header {
      background-color: $card-header-bg;
      border-bottom: 1px solid $card-header-border;
      padding: 0 1rem;
      & .card-header__title {
        & .material-icons-round {
          color: $card-icon-color;
        }
        & span[class*="widget_img-"] {
          filter: $card-icon-filter;
        }
        & .title__main {
          color: $card-title-color;
        }
      }
    }
    & .card-tab {
      background-color: $card-tab;
      & li {
        background-color: $card-tab-li;
        & a {
          color: $card-tab-li-txt;
        }
        &.on {
          background-color: $card-tab-li-active;
          & a {
            color: $card-tab-txt;
          }
        }
      }
    }
    & .card-header__tab {
      & li a {
        color: $form-txt;
        &::after {
          background-color: $card-tab-2;
        }
      }
      & li.on a {
        color: $card-tab-2;
      }
    }
    & .card-button {
      border-top: 1px solid $form-border;
      background-color: $card-button-body;
    }
  }

  //이너 아이템
  & .inner-item {
    background-color: $depth-2;
    border: 1px solid $form-border;
    & .item-header {
      background-color: $depth-3;
      border-bottom: 1px solid $form-border;
      & .item-header__title {
        & p {
          color: $secondary !important;
        }
        & h3 {
          color: $form-active-txt !important;
        }
      }
    }
  }

  //모달
  & .pnt-modal {
    & .modal-header {
      background-color: $card-header-bg;
      border-bottom: 1px solid $form-border !important;
    }
    & .modal-body {
      background-color: $card-body-bg;
    }
    & .modal-footer {
      padding: 0.75rem;
      background-color: $card-button-body;
      border-top: 1px solid $form-border !important;
    }
  }

  /////////////////////////////////////////////////////////
  //위젯
  // 전체 현황 숫자
  & .widget-item-nums {
    display: flex;
    gap: 8px;
    & .col {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px;
      background-color: $depth-5;
      border: 1px solid $depth-5;
      border-radius: $form-radius;
      & * {
        color: $form-txt !important;
      }
      & .item-num {
        font-size: 26px;
        font-weight: 900 !important;
        & span {
          font-size: 20px;
          margin: 0 5px;
          font-weight: 500;
          opacity: 0.7;
        }
      }

      // 선택
      &.selected {
        background-color: $widget-active-bg;
        border: 1px solid $widget-active-border;
        box-shadow: $card-shadow;
        transition: $form-transition;
        cursor: pointer;
        & * {
          color: $widget-active-txt !important;
        }
        &:hover {
          filter: none;
        }
      }
      // 미선택
      &.nonSelected {
        background-color: $depth-5;
        transition: $form-transition;
        cursor: pointer;
        &:hover {
          transition: $form-transition;
          filter: brightness(95%);
        }
      }
      // readOnly
      &.view {
        background-color: $depth-3;
        border: 1px solid $form-border;
      }
    }
  }

  // 위젯 추가시 보여지는 리스트
  & .widget-add-popup {
    & .modal-body {
      & > div:last-child {
        border-left: 1px solid $depth-8;
      }
    }
    & .list-group {
      //위젯 생성시 나오는 리스트 아이템 목록 css
      & .list-group-item {
        background-color: $card-body-bg;
        color: $form-txt;
        margin-top: 0;
        border: 0;
        &.layout {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          margin-top: 0;
          border: 0;

          & .badge {
            font-size: 80%;
            line-height: normal;
          }
          &:hover {
            background-color: $depth-4;
            font-weight: 700;
          }

          &.active {
            font-weight: 800 !important;
            background-color: $depth-6;
            &:hover {
              background-color: $depth-6;
            }
          }
        }
        &:hover {
          background-color: $depth-4;
        }
      }
    }
  }

  /////////////////////////////////////////////////////////
  //기본 폼
  //1. 버튼

  & .pnt-btn {
    background-color: $gray-300;
    color: $form-txt;
    &.btn-brand {
      background-color: $brand;
      color: white;
      & .material-icons-round {
        color: white;
      }
    }
    &.btn-secondary {
      background-color: $secondary;
      color: white;
      & .material-icons-round {
        color: white;
      }
    }
    &.btn-danger {
      background-color: $danger;
      color: white;
      & .material-icons-round {
        color: white;
      }
    }
    &.btn-lightgray {
      background-color: $light-gray;
      color: $form-txt;
      & .material-icons-round {
        color: $form-txt;
      }
    }
    &.btn-gray {
      background-color: $gray;
      color: white;
      & .material-icons-round {
        color: white;
      }
    }
    &.btn-darkgray {
      background-color: $dark-gray;
      color: white;
      & .material-icons-round {
        color: white;
      }
    }
    &.btn-line {
      background-color: transparent;
      border: 1px solid $gray-600 !important;
      &:hover {
        color: $brand;
        border: 1px solid $brand !important;
        & .material-icons-round {
          color: $brand;
        }
      }
    }
    &.btn-trans {
      background-color: transparent;
      &:hover {
        color: $brand !important;
        & .material-icons-round {
          color: $brand !important;
        }
      }
    }
  }
  //2. 인풋[텍스트]
  & .pnt-input--group {
    & input {
      background-color: $input-bg;
      border: 1px solid $input-border;
      color: $form-active-txt;
      &::placeholder {
        color: $form-placeholder-txt;
      }
    }
    input:focus,
    input:active,
    input:focus-visible {
      border: 1px solid $form-active-border;
    }
    &.input-error {
      & p,
      b,
      span,
      div {
        color: $danger;
      }
      & .pnt-input {
        border: 1px solid $default-danger;
      }
    }
    &.form-must {
      & p,
      b,
      span,
      div {
        color: $danger;
      }
      & input {
        border: 1px solid $brand;
      }
    }
    &.input-disable {
      & .pnt-input {
        background-color: $form-disable-bg;
      }
      &::placeholder {
        color: $form-disable-txt;
      }
    }
  }
  //3. 인풋[파일]
  & .pnt-file--group {
    & input[type="file"] {
      border: 1px solid $form-border;
      background-color: $form-bg;
      &::before {
        background-color: $form-bg;
        border-left: 1px solid $form-border;
      }
    }
    & label {
      background-color: $depth-8;
      border: 1px solid $form-border;
      color: $secondary;
      & .material-icons-round {
        color: $secondary;
      }
    }
  }
  //4. 셀렉트
  & .pnt-select--group {
    & .select__btn {
      background-color: $form-bg;
      border: 1px solid $form-border;
      & .cont-wrap {
        color: $form-active-txt;
      }
      & .material-icons-round {
        color: $form-txt;
      }
    }
    & .select__options {
      background-color: $depth-3;
      & .select__option {
        color: $form-txt;
        border-top: 1px solid $form-border;
        &:hover {
          background-color: $depth-6;
        }
      }
      & li {
        &.on a {
          border-top: 1px solid $form-border;
          background-color: $form-active-bg-light;
          color: $form-active-txt-light;
        }
      }
    }
    //활성화 일때
    &.on {
      & .select__btn,
      & .select__options {
        border: 1px solid $form-active-border;
      }
    }
    &.form-must {
      & span {
        color: $danger;
      }
      & .select__btn {
        border: 1px solid $brand;
      }
    }
    &.form-disable .cont-wrap {
      color: $form-disable-txt;
    }
  }
  //5. 체크박스
  .pnt-checkbox {
    & p {
      color: $form-txt;
    }
    & .checkbox-effect {
      border: 1px solid $depth-9;
      background-color: $depth-6;
      &::before,
      &::after {
        background: white;
      }
    }
    &:has(input:checked) {
      & .checkbox-effect {
        background-color: $form-active-bg;
      }
      & p {
        color: $form-active-txt;
      }
    }
    &.form-disable p {
      color: $form-disable-txt;
    }
  }
  //6. 토글
  .pnt-toggle {
    & input:checked + .slider {
      background-color: $success;
    }
    & .slider {
      background-color: $toggle-bg;
      &::before {
        background-color: $toggle-bar-bg;
      }
    }
    & p {
      &:nth-of-type(1) {
        color: $form-txt;
      }
      &:nth-of-type(2) {
        color: $form-txt;
      }
    }
  }
  //7. 라디오
  & .pnt-radio {
    border: 1px solid $form-border;
    background-color: $form-bg;
    & p {
      color: $form-txt;
    }
    & input {
      &::after {
        border: 2px solid $form-bg !important;
      }
    }
    //체크시
    &:has(input:checked) {
      border: 1px solid $brand;
      & input {
        border: 0.5em solid $brand !important;
      }
      & p {
        color: $form-active-txt;
      }
    }
    //비활성화시
    &:has(input:disabled) {
      background-color: $form-disable-bg;
      & p {
        color: $form-disable-txt;
      }
    }
  }
  //8. 네임플레이트
  & .pnt-nameplate {
    background-color: $nameplate-bg;
    & .cont-wrap {
      color: $nameplate-txt;
    }
  }
  //9. 텍스트
  & .pnt-txt {
    color: $form-txt;
    &.txt-border {
      border: 1px solid $form-border;
    }
    &.txt-bg {
      background-color: $form-bg;
    }
  }
  //10. 라벨
  & .pnt-label--group {
    & .label-main {
      color: $form-active-txt;
    }
    & .label-options {
      color: $form-txt;
    }
  }
  //11. 디바이더
  & .pnt-divider {
    background-color: $form-border;
  }
  //12_1. 테이블[페이지네이션]
  & .pagination {
    & button:nth-of-type(2),
    button:nth-of-type(3) {
      //background-color: $gray-700;
    }
    & button:nth-of-type(1),
    button:nth-of-type(4) {
      //background-color: $gray-200;
    }
    & .now,
    & .whole {
      color: $form-txt;
    }
  }
  //12_2. 테이블
  & .pnt-table {
    &.table-for-viewer {
      & .border-box .tbody .tr:hover {
        background-color: $table-tbody-bg !important;
      }
    }
    & .th,
    & .td {
      border-bottom: 1px solid $table-inner-bottom-border;
      border-right: 1px solid $table-inner-border;
    }
    & .border-box {
      border-top: 1.4px solid $table-border;
      border-bottom: 1.4px solid $table-border;
      & .thead {
        & .tr {
          border-bottom: 1px solid $table-header-border;
        }
        & .th {
          background-color: $table-thead-bg;
          color: $table-th-txt;
        }
      }
      & .tbody {
        background-color: $table-tbody-bg;
        & .tr {
          &:nth-child(2n) {
            background-color: $table-2row-bg;
          }
          &:hover {
            background-color: $table-hover-bg;
          }
          &.active {
            background-color: $table-clicked-bg;
            & .td {
              color: $table-clicked-txt;
            }
          }
          & .td {
            color: $table-td-txt;
          }
        }
      }
    }
  }

  ////////////////////////////////
  //form 공통
  & .form-disable {
    //버튼
    &.pnt-btn {
      background-color: $form-disable-bg !important;
      box-shadow: 0 0 0 1px $form-border inset;
      color: $form-disable-txt !important;
    }
    //인풋
    &.pnt-input--group .pnt-input {
      background-color: $form-disable-bg;
      color: $form-disable-txt;
    }
    //인풋[파일]
    &.pnt-file--group {
      & input[type="file"] {
        border: 1px solid $form-border;
        background-color: $form-disable-bg;
        color: $form-disable-txt !important;
        &::before {
          background-color: $form-disable-bg;
          border-left: 1px solid $form-border;
        }
      }
      & label {
        background-color: $depth-8;
        border: 1px solid $form-border;
        color: $form-disable-txt;
        & .material-icons-round {
          color: $form-disable-txt;
        }
      }
    }
    //셀렉트
    &.pnt-select--group {
      & .select__btn {
        background-color: $form-disable-bg;
        & .cont-wrap {
          color: $form-disable-txt;
        }
      }
    }
    //체크박스
    &.pnt-checkbox .checkbox-effect {
      background-color: $form-disable-bg;
    }
    //토글
    &.pnt-toggle {
      & input:checked + .slider {
        background-color: $success-d2;
      }
      & .slider {
        background-color: $gray-600;
        &::before {
          background-color: $gray-500;
        }
      }
    }
  }

  ///////////////
  //스크롤바
  *::-webkit-scrollbar-thumb {
    background-color: $scrollbar-bg;
  }
  *::-webkit-scrollbar-track {
    background-color: $scrollbar-track-bg;
  }

  ///////////////////////////
  //컬러들
  .color-brand {
    color: $brand !important;
  }
  .color-secondary {
    color: $secondary !important;
  }
  .color-success {
    color: $success !important;
  }
  .color-info {
    color: $info !important;
  }
  .color-warning {
    color: $warning !important;
  }
  .color-danger {
    color: $danger !important;
  }
  .color-lightgray {
    color: $light-gray !important;
  }
  .color-gray {
    color: $gray !important;
  }
  .color-darkgray {
    color: $dark-gray !important;
  }
  .color-white {
    color: white !important;
  }
  .color-black {
    color: black !important;
  }
  .color-trans-black {
    color: $trans-black !important;
  }

  .bg-brand {
    background-color: $brand !important;
    &.bg-light {
      background-color: $brand-l2 !important;
    }
    &.bg-dark {
      background-color: $brand-d2 !important;
    }
    &.bg-d-4 {
      background-color: $brand-d4 !important;
    }
    &.bg-d-3 {
      background-color: $brand-d3 !important;
    }
    &.bg-d-2 {
      background-color: $brand-d2 !important;
    }
    &.bg-d-1 {
      background-color: $brand-d1 !important;
    }
    &.bg-l-4 {
      background-color: $brand-l4 !important;
    }
    &.bg-l-3 {
      background-color: $brand-l3 !important;
    }
    &.bg-l-2 {
      background-color: $brand-l2 !important;
    }
    &.bg-l-1 {
      background-color: $brand-l1 !important;
    }
  }
  .bg-secondary {
    background-color: $secondary !important;
    &.bg-light {
      background-color: $secondary-l2 !important;
    }
    &.bg-dark {
      background-color: $secondary-d2 !important;
    }
    &.bg-d-4 {
      background-color: $secondary-d4 !important;
    }
    &.bg-d-3 {
      background-color: $secondary-d3 !important;
    }
    &.bg-d-2 {
      background-color: $secondary-d2 !important;
    }
    &.bg-d-1 {
      background-color: $secondary-d1 !important;
    }
    &.bg-l-4 {
      background-color: $secondary-l4 !important;
    }
    &.bg-l-3 {
      background-color: $secondary-l3 !important;
    }
    &.bg-l-2 {
      background-color: $secondary-l2 !important;
    }
    &.bg-l-1 {
      background-color: $secondary-l1 !important;
    }
  }
  .bg-success {
    background-color: $success !important;
    &.bg-light {
      background-color: $success-l2 !important;
    }
    &.bg-dark {
      background-color: $success-d2 !important;
    }
    &.bg-d-4 {
      background-color: $success-d4 !important;
    }
    &.bg-d-3 {
      background-color: $success-d3 !important;
    }
    &.bg-d-2 {
      background-color: $success-d2 !important;
    }
    &.bg-d-1 {
      background-color: $success-d1 !important;
    }
    &.bg-l-4 {
      background-color: $success-l4 !important;
    }
    &.bg-l-3 {
      background-color: $success-l3 !important;
    }
    &.bg-l-2 {
      background-color: $success-l2 !important;
    }
    &.bg-l-1 {
      background-color: $success-l1 !important;
    }
  }
  .bg-info {
    background-color: $info !important;
    &.bg-light {
      background-color: $info-l3 !important;
    }
    &.bg-dark {
      background-color: $info-d3 !important;
    }
    &.bg-d-4 {
      background-color: $info-d4 !important;
    }
    &.bg-d-3 {
      background-color: $info-d3 !important;
    }
    &.bg-d-2 {
      background-color: $info-d2 !important;
    }
    &.bg-d-1 {
      background-color: $info-d1 !important;
    }
    &.bg-l-4 {
      background-color: $info-l4 !important;
    }
    &.bg-l-3 {
      background-color: $info-l3 !important;
    }
    &.bg-l-2 {
      background-color: $info-l2 !important;
    }
    &.bg-l-1 {
      background-color: $info-l1 !important;
    }
  }
  .bg-warning {
    background-color: $warning !important;
    &.bg-light {
      background-color: $warning-l3 !important;
    }
    &.bg-dark {
      background-color: $warning-d3 !important;
    }
    &.bg-d-4 {
      background-color: $warning-d4 !important;
    }
    &.bg-d-3 {
      background-color: $warning-d3 !important;
    }
    &.bg-d-2 {
      background-color: $warning-d2 !important;
    }
    &.bg-d-1 {
      background-color: $warning-d1 !important;
    }
    &.bg-l-4 {
      background-color: $warning-l4 !important;
    }
    &.bg-l-3 {
      background-color: $warning-l3 !important;
    }
    &.bg-l-2 {
      background-color: $warning-l2 !important;
    }
    &.bg-l-1 {
      background-color: $warning-l1 !important;
    }
  }
  .bg-danger {
    background-color: $danger !important;
    &.bg-light {
      background-color: $danger-l3 !important;
    }
    &.bg-dark {
      background-color: $danger-d2 !important;
    }
    &.bg-d-4 {
      background-color: $danger-d4 !important;
    }
    &.bg-d-3 {
      background-color: $danger-d3 !important;
    }
    &.bg-d-2 {
      background-color: $danger-d2 !important;
    }
    &.bg-d-1 {
      background-color: $danger-d1 !important;
    }
    &.bg-l-4 {
      background-color: $danger-l4 !important;
    }
    &.bg-l-3 {
      background-color: $danger-l3 !important;
    }
    &.bg-l-2 {
      background-color: $danger-l2 !important;
    }
    &.bg-l-1 {
      background-color: $danger-l1 !important;
    }
  }
  .bg-gray {
    background-color: $gray !important;
    &.bg-light {
      background-color: $gray-l !important;
    }
    &.bg-dark {
      background-color: $gray-d !important;
    }
    &.bg-d-4 {
      background-color: $gray-800 !important;
    }
    &.bg-d-3 {
      background-color: $gray-700 !important;
    }
    &.bg-d-2 {
      background-color: $gray-600 !important;
    }
    &.bg-d-1 {
      background-color: $gray-500 !important;
    }
    &.bg-l-4 {
      background-color: $gray-400 !important;
    }
    &.bg-l-3 {
      background-color: $gray-300 !important;
    }
    &.bg-l-2 {
      background-color: $gray-200 !important;
    }
    &.bg-l-1 {
      background-color: $gray-100 !important;
    }
  }
  .bg-lightgray {
    background-color: $light-gray !important;
    &.bg-light {
      background-color: $light-gray-l !important;
    }
    &.bg-dark {
      background-color: $light-gray-d !important;
    }
  }
  .bg-darkgray {
    background-color: $dark-gray !important;
    &.bg-light {
      background-color: $dark-gray-l !important;
    }
    &.bg-dark {
      background-color: $dark-gray-d !important;
    }
  }
  .bg-trans-black {
    background-color: $trans-black;
  }

  .border-brand {
    border: 1px solid $brand !important;
  }
  .border-secondary {
    border: 1px solid $secondary !important;
  }
  .border-success {
    border: 1px solid $success !important;
  }
  .border-info {
    border: 1px solid $info !important;
  }
  .border-warning {
    border: 1px solid $warning !important;
  }
  .border-danger {
    border: 1px solid $danger !important;
  }
  .border-gray {
    border: 1px solid $gray !important;
  }
  .border-lightgray {
    border: 1px solid $light-gray !important;
  }
  .border-darkgray {
    border: 1px solid $dark-gray !important;
  }

  /////////////////////////////////////////////////////////
  //리액트 영역 - 프론트에서 수정할 부분이 있으면 하위에 추가해주세요.
  //react-datePicker
  & .pnt-datepicker-container {
    & .form-control {
      height: 28px;
      border-top-right-radius: 2px !important;
      border-bottom-right-radius: 2px !important;
      font-size: 0.8rem;
      &:hover {
        cursor: pointer;
        filter: brightness(0.98);
      }
      &:focus {
        outline: 0;
        box-shadow: 0 0 0 1px $form-active-border;
      }
    }

    & .react-datepicker__input-container input {
      background-color: $form-bg;
      border: 1px solid $form-border;
      &.form-disable {
        background-color: $gray-200;
        color: $gray-500;
        opacity: 1;
      }
    }
    & .input-group-text {
      background-color: $form-disable-bg;
      border: 1px solid $form-border;
    }
    & .react-datepicker {
      background-color: $inner-card-body-bg;
      border-radius: 0;
      border: 1px solid $form-border;
      *::-webkit-scrollbar-track {
        background-color: $table-thead-bg;
      }
      *::-webkit-scrollbar-thumb {
        background-color: $form-bg;
      }

      .react-datepicker__triangle {
        border-bottom-color: $form-bg;
        border-top-color: $form-bg;
        &::before {
          border-bottom-color: $form-border;
          border-top-color: $form-border;
        }
      }

      & .react-datepicker__navigation {
        top: 12px;

        &--previous:hover {
          border-right-color: $form-active-border;
        }
        &--next:hover {
          border-left-color: $form-active-border;
        }
      }

      /**
       * month 영역
       */
      & .react-datepicker__month-container {
        & .react-datepicker__header {
          background-color: $table-thead-bg;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom: 1px solid $form-border;

          & .react-datepicker__day-names {
            background-color: $form-bg;
            border-top: 1px solid $form-border;
            margin-top: 0.5rem;
          }
        }
        & .react-datepicker__week {

          & .react-datepicker__day {
            &:hover,
            &:focus,
            &:active {
              font-weight: 700;
              background-color: $form-bg;
            }

            &.react-datepicker__day--keyboard-selected {
              background-color: #545cd8;
              color: white;
            }

            &.react-datepicker__day--disabled {
              color:$depth-9;
            }
          }
        }

      }

      /**
       * time 영역
       */
      & .react-datepicker__time-container {
        border-left: 1px solid $form-border;

        & .react-datepicker__header.react-datepicker__header--time {
          background-color: $table-thead-bg;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom: 1px solid $form-border;
        }
        & .react-datepicker__time {
          background-color: $inner-card-body-bg;
          & .react-datepicker__time-box {
            & .react-datepicker__time-list {
              &::-webkit-scrollbar {
                display: none;
              }
              &::-webkit-scrollbar-track {
                display: none;
              }
              &::-webkit-scrollbar-thumb {
                display: none;
              }
              & .react-datepicker__time-list-item {
                border-bottom: 1px solid $form-border;
                &:hover {
                  background-color: $form-hover-bg !important;
                }
                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
      & .react-datepicker__input-time-container {
        & .react-datepicker-time__input {
          border-left: 1px solid $form-border;
          & .react-datepicker__header {
            background-color: $table-thead-bg;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom: 1px solid $form-border;
          }


          & .time-label-box {
            border-top: 1px solid $form-border;
            border-bottom: 0;
            margin-top: 0.5rem;
            div:last-child {
              border-left: 1px solid $form-border;
            }
          }
          & .list-group {
            &:last-child {
              border-left: 1px solid $form-border;
            }
            & .list-wrap {
              &::-webkit-scrollbar {
                display: none;
              }
              &::-webkit-scrollbar-track {
                display: none;
              }
              &::-webkit-scrollbar-thumb {
                display: none;
              }
              & .list-group-item {
                width: 32.5px;
                height: 32px;
                padding: 0;
                margin: 0;
                color: $base-txt;
                background-color: $inner-card-body-bg;
                border: {
                  radius: 0;
                  top: 0;
                  right: 0;
                  bottom: 1px solid $form-border;
                  left: none;
                }

                &:hover {
                  background-color: $form-hover-bg !important;
                }
                &:last-child {
                  border-bottom: none;
                }
                &.active {
                  font-weight: 700;
                  &:hover {
                    background-color: #545cd8 !important;
                    color: #fff !important
                  }
                }
              }
            }
          }
        }
        & .time-label-box {
          background-color: $form-bg;
        }
      }
    }
    & .input-group-append {
      & .input-group-text {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        padding: 0 0.5rem;
        & .material-icons-round {
          margin-top: -1px;
        }
      }
    }
    & .input-group-prepend {
      & .input-group-text {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        padding: 0 0.5rem;
        & .material-icons-round {
          margin-top: -1px;
        }
      }
    }
  }
  //기본 리액트 셀렉트 컴포넌트
  & .react-select {
    &:hover {
      -webkit-filter: brightness(0.98);
      filter: brightness(0.98);
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
    }
    & .react-select__control {
      cursor: pointer;
      box-shadow: none;

      & .select__btn {
        background-color: $form-bg;
      }
    }
    & .react-select__control--menu-is-open {
      box-shadow: 0 0 0 1px $form-active-border;
    }
    & .react-select__multi-value {
      & .react-select__multi-value__remove {
        &:hover,
        &:focus,
        &:active {
          background-color: $table-hover-bg;
        }
      }
      background-color: $table-thead-bg;
    }
    & .react-select__indicator {
      opacity: 0.5;
      & svg {
        height: 16px;
        width: 18px;
      }
    }
    & .react-select__placeholder {
      font-size: 0.8rem;
    }
  }
  //option 클래스
  & .react-select__menu {
    background-color: $form-bg;
    & .react-select__menu-list {
      padding: 0;
      border-radius: 5px;
      & .react-select__option {
        cursor: pointer;
        font-size: 0.8rem;
        color: $form-txt;
        border-bottom: 1px solid $table-thead-bg;
        background-color: $form-bg;
        &:hover,
        &:focus,
        &:active {
          background-color: $table-hover-bg;
        }
        &:last-child {
          border-bottom: none;
        }
      }
      & .react-select__menu-notice--no-options {
        font-size: 0.8rem;
        color: $form-txt;
        border-bottom: 1px solid $table-thead-bg;
        background-color: $form-bg;
      }
    }
  }
  //리액트 셀렉트(커스텀 컴포넌트)
  & .select-dropdown {
    & .styled-option {
      &:hover,
      &:focus,
      &:active {
        background-color: $table-hover-bg;
      }
    }
    & .select-control {
      background-color: $form-bg;
      box-shadow: none;
      &:hover,
      &:focus,
      &:active {
        box-shadow: none;
        border: 1px solid $form-active-border;
      }
    }
    & .select-container {
      background-color: $form-bg;
      border: 1px solid $form-border;
      box-shadow: none;
      &.container-open {
        box-shadow: 0 0 0 1px $form-active-border;
      }
      &:hover {
        -webkit-filter: brightness(0.98);
        filter: brightness(0.98);
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
      }
    }
    & .select-menu {
      background-color: $form-bg;
    }
    & .select-option {
      > div {
        &:hover,
        &:focus,
        &:active {
          background-color: $table-hover-bg;
        }
        background-color: $form-bg;
        & .select-label {
          color: $base-txt;
        }
      }
    }
    & .select-group-title {
      border-bottom: 1px solid $base-txt;
      padding: 4px 8px;
      font-weight: bold;
    }
    & .rct-icon {
      color: $base-txt;
    }
    & .tree-wrapper {
      border: 1px solid $base-txt;
      padding: 5px;
    }
    & .select-dropdown-svg {
      opacity: 0.5;
    }
  }

  // setting menu dropdown
  .grid-sub-menu {
    background-color: $depth-4;
  }
}


